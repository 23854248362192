import { Attribute, Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

export const MAIN_COLOR = '#007AFF';
export const ACCENT_COLOR = '#FD7F50';
// export const ACCENT_COLOR = '#ff7f50';
export const WARN_COLOR = '#5352ED';
export const BLACK_COLOR = '#000';
export const RED_COLOR = '#CF142B';
export const DARK_BLUE = '#5352ED';
export const ORANGE_COLOR = '#FD7F50';
export const NIGHT = '#1a2640';


@Directive({ selector: '[round-button]' })
export class RoundButton implements OnInit {

    private commands: any[] = [];
    filled: boolean = false;
    cssColor: string;
    sizeClass:string = 'medium';

    constructor(
        private renderer: Renderer2,
        private el: ElementRef,
        @Attribute('filled') filled = 'filled',
        @Attribute('color') color: string,
        @Attribute('size') size: string
    ) {
        this.sizeClass = size?size:'medium';
        let cssColor = MAIN_COLOR;

        switch (color) {
            case 'main': {
                cssColor = MAIN_COLOR;
                break;
            }
            case 'accent': {
                cssColor = ACCENT_COLOR;
                break;
            }
            case 'warn': {
                cssColor = WARN_COLOR;
                break;
            }
            case 'black': {
                cssColor = BLACK_COLOR;
                break;
            }
            case 'red': {
                cssColor = RED_COLOR;
                break;
            }
            case 'dark_blue': {
                cssColor = DARK_BLUE;
                break;
            }
            case 'orange': {
                cssColor = ORANGE_COLOR;
                break;
            }
            case 'night': {
                cssColor = NIGHT;
                break;
            }
            default: {
                //statements;
                break;
            }
        }

        this.filled = filled === 'filled' ? true : false;
        this.cssColor = cssColor;
        this.renderer.addClass(this.el.nativeElement, color);
    }

    ngOnInit() {
        this.renderer.addClass(this.el.nativeElement, 'round-btn');
        this.renderer.addClass(this.el.nativeElement, this.sizeClass);

        if (this.filled) {
            this.renderer.setStyle(this.el.nativeElement, 'background-color', this.cssColor);
            this.renderer.addClass(this.el.nativeElement, 'filled');
        }
        else {
            this.renderer.setStyle(this.el.nativeElement, 'border-color', this.cssColor);
            this.renderer.setStyle(this.el.nativeElement, 'color', '#fff');
            this.renderer.addClass(this.el.nativeElement, 'empty');
        }

    }



}
