import { Component, Inject, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { GameCategory } from 'src/app/apollo/models/base-models';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { LICENCE } from 'src/app/app-routing.module';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { FrontPageGamesService } from 'src/app/services/page/front-games.service';
import { getMappedCategoryLink } from '../../../router-mapping';
import { isPlatformServer } from '@angular/common';
import { LicenceService } from 'src/app/services/utils/licence.service';

@Component({
  selector: 'app-game-category-dialog',
  templateUrl: './game-category-dialog.component.html',
  styleUrls: ['./game-category-dialog.component.scss']
})
export class GameCategoryDialogComponent extends BasePageComponent {

  constructor(
    private router: Router,
    public detectDeviceService: DetectDeviceService,
    protected translationConfig: TranslationConfig,
    private frontPageGamesService: FrontPageGamesService,
    protected licenceService: LicenceService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LICENCE) public licence: string,
    @Inject(LOCALE_ID) public locale: string,
  ) { super() }

  categories: GameCategory[];
  isServer: boolean = false;

  ngOnInit(): void {
    this.frontPageGamesService.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
      this.categories = response.pagesCategories[0]?.categories;
    });
    if (isPlatformServer(this.platformId)) {
      this.isServer = true;
    }
  }

  public redirectUrl: string = null;

  closeDialog(): void {
  }

  openCategory(category: GameCategory) {
    this.router.navigate([getMappedCategoryLink(category, this.translationConfig)]);
  }

}
