<div fxLayoutAlign="start" class="game-card-wrapper">
  <div class="game-card desktop" [ngClass]="size">
    <!-- <img (click)="openGameDialog()" src="{{ data.thumbnail.url }}" /> -->
    <div class="thumbnail" [style.background-color]="data.initialLoadColor ? data.initialLoadColor.css : 'rgb(28 146 62 / 59%)'">
      <a [routerLink]="launchGameUrl" (click)="launchGame($event,gameNameSlug)">
        <div class="play-effect">
          <img src="assets/desktop/play-button-overlay.svg">
        </div>
        <ng-container>
          <img *ngIf="data['thumbnailDesktop'+size] else common_img" loading="lazy" alt="{{data.name}}" class="swiper-lazy" data-src="{{  data['thumbnailDesktop'+size]['url'] }}" />
          <ng-template #common_img>
            <img *ngIf="data['thumbnailDesktopSquare']" loading="lazy" alt="{{data.name}}" class="swiper-lazy" data-src="{{ data?.thumbnailDesktop?.url }}" />
          </ng-template>
          <!-- <div class="badge" *ngIf="isWinDropBadge">
            <img src="assets/icons/drops-win-icon{{size === 'Rectangular'? '-rectangular': ''}}.png">
          </div> -->

          <div class="badge" [ngSwitch]="badge()">
            <ng-container *ngSwitchCase="'big_win'">
              <img class="badge-tag big-win" src="assets/icons/badge-bigwin-desktop.svg">
            </ng-container>
            <ng-container *ngSwitchCase="'hot'">
              <img class="badge-tag" src="assets/icons/badge-hot-desktop.svg">
            </ng-container>

            <ng-container *ngSwitchCase="'drops_win'">
              <img src="assets/icons/drops-win-icon.png">
            </ng-container>

            <ng-container *ngSwitchCase="'cash_drops'">
              <img src="assets/icons/tag-thumbnail-desktop-kalamba.png">
            </ng-container>


            <ng-container *ngSwitchCase="'take_prize'">
              <img src="assets/icons/take-the-prize-desktop.png">
            </ng-container>

            <ng-container *ngSwitchDefault>

            </ng-container>
          </div>
          <div *ngIf="data?.tag?.includes('exclusive')" class="exclusive">
            <img src="{{licenceService.getKey('icon-exclusive-desktop')}}">
          </div>

          <div *ngIf="data?.tag?.includes('power_weekends')" class="power-weekends">
            <img src="assets/icons/tag-power-weekends-pragmatic-desktop-2x.png">
          </div>
          <div *ngIf="data?.tag?.includes('holiday_spin_splash')" class="holiday-spin-splash">
            <img src="assets/icons/holiday-spin-splash-desktop-tag-2x.png">
          </div>

          <div *ngIf="data.uniquePlayers" fxLayout="row" fxLayoutAlign="center center" class="unique-players">
            <img src="assets/icons/icon-profile-sticky.svg" loading=lazy alt="signup" class="profile-icon" />
            <span>{{data.uniquePlayers}}</span>
          </div>

        </ng-container>
      </a>
    </div>
    <div class="title">
      {{data.name}}
    </div>
  </div>
</div>