import { gql } from 'apollo-angular';


export const STATIC_PAGE_CONTENT_QUERY = gql`query StaticPageContent($pageName: String!,$categoryName: String!,$licenseName: Licence!,$locale: Locale!){
    pages(locales: [$locale, en],where: { AND: {name: $pageName, category:$categoryName,OR:[{license:$licenseName},{license:all}] }}) {
        content {
          html
        }
        htmlContent
        name
        license
        pageType
        pageAdditionalContents{
          name
          content{
            html
          }
          url
        }
        title
        description
        landingPage
        displayName
        seo {
          metaTitle
          metaDescription
          relCanonical
          noIndex
        }
        headerBanner{
          url(transformation: {
            document: { output: { format: webp } },
          })
        }
        richBanner{
          name
          width
          height
        }
        desktopHeaderBanner{
          url(transformation: {
            document: { output: { format: webp } },
          })
        }
      }
  }
  `

export const SYSTEM_PAGE_CONTENT_QUERY = gql`query SystemPageContent($pageName: String!,$locale: Locale!){
    systemPage(locales: [$locale, en],where: {name: $pageName}) {
        header
        content {
          html
        }
      }
  }
  `

