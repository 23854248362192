import { gameTrx, mostPlayedGamesTrx, newGamesTrx, topGamesTrx } from './router-translation.labels';
import { GameCategory } from './apollo/models/base-models';
import { TranslationConfig } from './utils/translate-config';

// Mapping between the route and the category name

export const routerMapping: Map<string, string> = new Map([
  [gameTrx, gameTrx],
  [newGamesTrx, 'newest-games'],
  [mostPlayedGamesTrx, 'most played'],
  [topGamesTrx, 'top'],
  ['newest-games-CAD', 'newest-games-ca'],
  ['top-CAD', 'top-ca'],
  ['top-ZA', 'top-za'],
  ['newest-games-ZA', 'newest-games-za'],
  ['megaways-ZA', 'megaways-za']
]);

export function getMappedCategoryLink(category: GameCategory, translationConfig: TranslationConfig, useSlotsInPath: boolean = true): string {
  const slotsUrl = translationConfig.getTranslation(gameTrx);
  if (category.url) {
    return category.url;
  }
  let routeLink: string = null;
  routerMapping.forEach((trx, cat) => {
    if (trx === category.name) {
      routeLink = translationConfig.getTranslation(cat);
    }
  });

  return routeLink ?? (useSlotsInPath ? `${slotsUrl}/` : '') + category.name;
}
