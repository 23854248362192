import { SafeHtml } from '@angular/platform-browser';
import { SEO } from 'src/app/apollo/models/base-models';

export interface BOMission {
    ref: string
    games: number[]
    name: string
    masterRef: string
    bonusRef: string
    cmsId: string
    startDate: Date
    endDate: Date
    minBet: number
    minBetCurrencies: { [key: string]: number }
}

export interface CMSMission {
    id: string
    ref: string
    name: string
    licence: string[]
    isPrizeEngineEligiblePlayers: boolean
    games: {
        name
        gameid
        systemName
        thumbnail: {
            url: string
        }
    }[]
    thumbnailMobile?: {
        url: string
    }
    thumbnailDesktop?: {
        url: string
    }

    largeDesktopThumbnail?: {
        url: string
    }
    thumbnailDesktopSquare?: {
        url: string
    }
    thumbnailDesktopRectangular?: {
        url: string
    }
    missionsAchievements: MissionAchievement[],
    fixedCurrency: { [key: string]: number }

}

export enum MISSIONTYPE {
    ACTIVE,
    UPCOMMING,
    HISTORY,
    LOCKED
}

export interface PageDisplayMission {
    startDate: Date
    endDate: Date
    missionType: string
    minBetCurrencies: { [key: string]: number }
    minBet: number
    ref: string
    mission: CMSMission
}

export interface Mission {
    ref: string
    cmsId: string
    games: {
        name
        gameid
        systemName
        thumbnail: {
            url: string
        }
    }[]
    name: string
    startDate: Date
    endDate: Date
    missionType: MISSIONTYPE
    minBetCurrencies: { [key: string]: number }
    fixedCurrency: { [key: string]: number }
    minBet: number
    missionsAchievements: MissionAchievement[],
    thumbnailMobile?: {
        url: string
    }
    thumbnailDesktop?: {
        url: string
    }
    largeDesktopThumbnail?: {
        url: string
    }
    largeDesktopBanner?: {
        url: string
    }
    prize?: MissionPrize[]
    seo?: SEO
}



export enum AchievementType {
    STAKE = "stake",
    ROUNDS = "rounds",
    MAX_WIN_VAUE = "win_value",
    MAX_WIN_MULTIPLIER = "win_multiplier",
    WIN_N_TIMES = "win_n_times"
}

export interface MissionPrize {
    name: string
    value: number
}

export interface CMSMissionDescription {
    description: {
        html: string
    }
}

export interface MissionDescription {
    description: SafeHtml
}

export interface CMSMissionAchievement {
    achievementType: AchievementType,
    value: number
}

export interface MissionAchievement {
    achievementType: AchievementType
    value: number
    playerValue?: number,
    isCompleted?: boolean
}

export interface MissionPrizeEngineResponse {
    win_sum: number
    stake: number
    win_value: number
    win_multiplier: number
    rounds: number
    win_n_times: number
    is_completed: boolean
    completed_achievements: number
    fixedCurrency: number
}