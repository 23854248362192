<div class="page-body desktop-panel" [ngClass]="isDesktop ? 'desktop': 'mobile'">

    <div class="content">
        <div class="category-title-h1" i18n>pending withdrawals</div>
        <div class="results" *ngIf="pendingList?.results?.length > 0 else nothing">

            <div class="loop" *ngFor="let item of pendingList?.results;let last = last">
                <div class="entry" (click)="checkConfirmCancel(item)" fxLayout="row" fxLayoutAlign="center start">
                    <div class="logo" fxLayoutAlign="center center">
                        <div class="rectangle" fxLayoutAlign="center center">
                            <img src="{{licenceService.getKey('icon-withdrawal')}}" />
                        </div>
                    </div>
                    <div class="item" fxLayoutAlign="start center">
                        <div class="name-group">
                            <div class="type">
                                <div class="h16-22">
                                    {{item.operator.name}}
                                </div>
                                <div class="data">
                                    {{item.date | slice:0:10}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-tail" fxLayoutAlign="start center">
                        <div class="tail-group">
                            <div class="amount h16-22">
                                {{item.transaction_value}} {{item.transaction_currency}}
                            </div>
                            <div class="h12">
                                <button round-button size="small" filled="empty" color="warn" class="button-upper" i18n>cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!last" class="divider">
                    <mat-divider></mat-divider>
                </div>
            </div>
        </div>
        <ng-template #nothing>
            <div class="no-list" i18n>There are no pending withdrawals at the moment.</div>
        </ng-template>
    </div>
</div>
