import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { DESKTOP_LOGIN, DialogConfig, FULL_DIALOG_CONFIG } from 'src/app/components/dialog/dialog.config';
import { lostPasswordTrx, supportTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { LostPasswordDialogComponent } from '../../lost-password-dialog/lost-password-dialog.component';

@Component({
  selector: 'app-wrong-password',
  templateUrl: './wrong-password.component.html',
  styleUrls: ['./wrong-password.component.scss']
})
export class WrongPasswordComponent extends BasePageComponent {
  dialogSize: DialogConfig = this.detectDeviceService.isDesktop() ? DESKTOP_LOGIN  : FULL_DIALOG_CONFIG;


  constructor(
    protected translationConfig: TranslationConfig,
    public detectDeviceService: DetectDeviceService,
    public dialog: MatDialog,
  ) { super() }

  @Output() closeEvent = new EventEmitter();


  ngOnInit(): void {
  }

  lostPassword() {
    // console.log('/' + this.translationConfig.getTranslation(lostPasswordTrx))
    // this.closeEvent.next('/' + this.translationConfig.getTranslation(lostPasswordTrx));
    
    const dialogRef = this.dialog.open(LostPasswordDialogComponent, {
      ...this.dialogSize,
      backdropClass: "desktopBg",
      data: {},
      panelClass: "desktop-panel"
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (dialogRef.componentInstance.redirectUrl) {
        this.closeDialog(dialogRef.componentInstance.redirectUrl);
      }
    });
  }

  closeDialog(url: string): void {
    this.closeEvent.next(url);
  }

  contactSupport() {
    this.closeEvent.next('/' + supportTrx);
  }

}
