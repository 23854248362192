import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, effect } from '@angular/core';
import { Router } from '@angular/router';
import { GameItem } from 'src/app/apollo/models/base-models';
import { gameInfoTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { DialogClosePreviousUrl } from 'src/app/services/utils/dialog-close-previouse-url.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { BasePageComponent } from '../../base-page/base-page.component';
import { TopGamesService } from 'src/app/services/profile/top-games.service';
import * as PIXI from 'pixi.js';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { TopGames } from 'src/app/services/profile/profile.models';
import { WINDOW } from '@ng-web-apis/common';

export type SelectedGameData = {
  name: string;
  thumbnail: {
    url: string;
  }
  thumbnailDesktop: {
    url: string
  }

  tag: string[];
  gameid: string;
  url: string;
  id: string;
  initialLoadColor: string

}
@Component({
  selector: 'app-game-card',
  templateUrl: './game-card.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./game-card.component.scss']
})
export class GameCardComponent extends BasePageComponent {

  isServer: boolean;
  topGames: TopGames;

  constructor(
    public detectDeviceService: DetectDeviceService,
    public translationConfig: TranslationConfig,
    private returnUrlService: DialogClosePreviousUrl,
    private router: Router,
    private topGamesService: TopGamesService,
    protected licenceService: LicenceService,
    @Inject(WINDOW) readonly windowRef: Window,
  ) {
    effect(() => {
      this.topGames = this.topGamesService.topGames();
      if (this._data) {
        this.checkTopGames();
      }
    });
    super()
  }

  launchGameUrl: string[];
  gameNameSlug: string;

  @Output() clickEvent = new EventEmitter<SelectedGameData>();

  @Input()
  set data(dataInput: GameItem) {
    if (this.topGames) {
      this.checkTopGames();
    }
    this._data = dataInput;
    this.gameNameSlug = dataInput.systemName;
    this.launchGameUrl = ['', this.translationConfig.getTranslation(gameInfoTrx), this.gameNameSlug];
  }
  @Input() isOutsideCategory: boolean = false;
  isWinDropBadge: boolean = false;
  isInTopGamesByMaxWin: boolean = false;
  isInTopGamesByTotalRounds: boolean = false;

  get data(): any { return this._data; }

  ngOnInit(): void {
    if (this.data && this.topGames) {
      this.checkTopGames();
    }
  }

  ngAfterViewInit(): void {
  }

  checkTopGames() {
    if (this.data?.gameid) {
      if (this.topGames.maxWin.includes(this.data.gameid)) {
        this.isInTopGamesByMaxWin = true;
      } else {
        if (this.isInTopGamesByMaxWin) {
          this.isInTopGamesByMaxWin = false;
        }
      }
      if (this.topGames.totalRounds.includes(this.data.gameid)) {
        this.isInTopGamesByTotalRounds = true;
      } else {
        if (this.isInTopGamesByTotalRounds) {
          this.isInTopGamesByTotalRounds = false;
        }
      }
    }
  }

  launchGame($event, gameSlug: string) {
    // this.loaderService.show();

    if (!this.isOutsideCategory) this.returnUrlService.setUrl(this.router.url.split('/'), this.windowRef.scrollY);

    // this.route.url.pipe(takeUntil(this.unsubscribe)).subscribe(url => {
    //   console.log(url)
    // })

    $event.preventDefault();

    // this.router.navigate(["", this.translationConfig.getTranslation(gameTrx), this.translationConfig.getTranslation(gameInfoTrx), gameSlug]).catch(() => {
    //   this.loaderService.hide();
    // })
  }

  badge() {
    if (this.isInTopGamesByMaxWin) {
      return 'big_win';
    } else if (this.isInTopGamesByTotalRounds) {
      return 'hot';
    } else if (this._data.tag?.includes('drops_win')) {
      return 'drops_win';
    } else if (this._data.tag?.includes('take_prize')) {
      return 'take_prize';
    } else if (this._data.tag?.includes('cash_drops')) {
      return 'cash_drops';
    } else {
      return null;
    }
  }

  openGameDialog() {
    this.clickEvent.next(this.data.initialLoadColor.css);
  }

}
