import { isPlatformServer } from '@angular/common';
import { Component, Inject, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { missionsTrx } from 'src/app/router-translation.labels';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { LoggedStatus } from 'src/app/services/auth/login/login.models';
import { Tournament } from 'src/app/services/tournaments/tournaments.models';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { environment } from 'src/environments/environment';
import { SwiperOptions } from 'swiper';
import { BasePageComponent } from '../../base-page/base-page.component';
import { MissionService } from 'src/app/services/missions/mission.service';
import { Mission } from 'src/app/services/missions/missions.models';


@Component({
  selector: 'app-missions-carousel',
  templateUrl: './missions-carousel.component.html',
  styleUrls: ['./missions-carousel.component.scss']
})
export class MissionsCarouselComponent extends BasePageComponent {
  public missionsTrx = this.translationConfig.getTranslation(missionsTrx);
  constructor(
    private missionService: MissionService,
    private loginStatus: LoginStatusService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platformId: string,
    private deviceDetectService: DeviceDetectorService,
    protected translationConfig: TranslationConfig,
    protected licenceService: LicenceService
  ) { super() }



  enableTournamentsDisplay = environment.enableTournamentsDisplay;
  missions: Mission[] = [];
  missionsCount: number = 0;
  isLogged: boolean;
  isComponentsReady = false;
  startDate = new Date(new Date().getTime() + (2 * 60 * 60 * 1000));
  offset = new Date().getTimezoneOffset();
  numberTournaments = 0;
  loginSubscritpion: any;
  isServer: boolean;

  config: SwiperOptions = {
    direction: 'horizontal',
    keyboard: true,
    slidesPerView: 1.2,
    centeredSlides: true,
    loop: false,
    simulateTouch: true,
    roundLengths: true,
    spaceBetween: 6
  };

  ngOnInit(): void {
    this.loginSubscritpion = this.loginStatus.getLoginStatus().pipe(
      filter(status => status.isLogged !== LoggedStatus.voidState),
      distinctUntilChanged(),
      switchMap((status) => {
        return this.missionService.updateMissionsStore().pipe(
          map(tournaments => ({ tournaments, isLogged: status.isLogged === LoggedStatus.logged }))
        )
      })
    ).subscribe(resp => {
      this.isLogged = resp.isLogged;
      this.numberTournaments = resp.tournaments.length;
      this.missions = resp.tournaments;
      if (!isPlatformServer(this.platformId)) {
        this.isServer = false;
      } else {
        this.isServer = true;
      }
      this.isComponentsReady = true;
      this.missionsCount = this.missions.length;
    })
  }

  ngOnDestroy(): void {
    if (this.loginSubscritpion) {
      this.loginSubscritpion.unsubscribe();
    }
  }

  trackByBannerItems(index: number, item: Tournament): string {
    return item.cmsId;
  }


}
