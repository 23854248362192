<div class="auth-content">
    <mat-divider></mat-divider>
  
    <div *ngIf="!isSubmited; else lostCompleted" class="lost-pass-form">
      <form name="lostPasswordForm" [formGroup]="lostPasswordForm" (ngSubmit)="onSubmit(lostPasswordForm)">
        <div class="auth-body mat-componets">
            <!-- <div class="image-box">
                <img src="assets/images/img-lemon-email-2.png">
            </div> -->

            <p style="padding-top: 8px;" i18n>Enter the email address that you have registered with Fireball Casino.</p>
  
            <mat-form-field appearance="outline">
                <mat-label i18n>E-mail</mat-label>
                <input matInput placeholder="   " type="text" formControlName="mail" cdkFocusInitial> <!-- placeholder="{{enterEmail}}" -->
                <img class="img-suffix" src="{{licenceService.getKey('icon-email')}}" matSuffix>
                <mat-hint *ngIf="lostPasswordForm.get('mail').errors && lostPasswordForm.get('mail').touched" class="error-hint" i18n><img class="img-hint" src="assets/icons/warning_hint_icon.png">Enter a valid emal address</mat-hint>
            </mat-form-field>
        
        </div>
  
        <div class="auth-footer">
          <button [class.spinner]="formSubmited" style="text-transform: uppercase;" round-button size="large" filled="filled" color="primary" type="submit">
            <span *ngIf="!formSubmited" i18n>submit</span><span *ngIf="formSubmited">&nbsp;</span></button>

            <p style="margin-top: -8px;">Follow the instructions in the email :)</p>
        </div>
      </form>
    </div>

    <ng-template #lostCompleted>
        <div class="auth-body">
            <!-- <div class="image-box">
                <img src="assets/images/lemon-envelope-sent-2.png">
            </div> -->

            <h2 i18n>Recovery email has been sent.</h2>

            <p style="width: 100%;">Now check your email to reset your password. See you soon!</p>
        </div>

        <div class="auth-footer">
            <button style="text-transform: uppercase;" round-button size="large" filled="filled" color="primary" (click)="closeDialog()" i18n>OK</button>
        </div>
    </ng-template>
</div>