const PHONE_VALID_COUNTRY_CODES = {
  "+36": [
    /^(20|30|31|50|70)/,
    /^\d{8,9}$/
  ],
  "+27": [
    /^(6|7|8)/,
    /^\d{8,11}$/
  ]
}

export const isSuffixValid = (number: string, countryCode: string): boolean => {
  if (!number) {
    return false;
  }
  if (!countryCode) {
    return false;
  }
  const countryCodeValidRegexps = PHONE_VALID_COUNTRY_CODES[countryCode];
  if (!!countryCodeValidRegexps) {
    for (let regexp of countryCodeValidRegexps) {
      if (!regexp.test(number)) {
        return false;
      }
    }
  }
  return true;
}


export const isPhoneNumberValid = (number: string): boolean => {

  const regexp = /^[+].\d{6,13}$/i;


  if (!number) {
    return false;
  }
  if (number === '') {
    return false;
  }

  if (!regexp.test(number)) {
    return false;
  }


  return true;

}

const COUNTRY_REPLACE_NUMBER = {
  "+36": [
    { from: /^06/, to: "" },
    { from: /^6/, to: "" },
  ],
  "+43": [
    { from: /^0/, to: "" },
  ],
  "+49": [
    { from: /^0/, to: "" },
  ],
  "+27": [
    { from: /^0/, to: "" },
  ]
}


export const patchTelephone = (number: string, countryCode: string): string => {
  if (!number) {
    return number;
  }
  if (!countryCode) {
    return number;
  }
  number = number.replace(/\s/g, '');
  const countryCodeReplace = COUNTRY_REPLACE_NUMBER[countryCode];
  if (!countryCodeReplace) {
    return number;
  }
  countryCodeReplace.forEach((replace) => {
    number = number.replace(replace.from, replace.to);
  })
  return number;
}