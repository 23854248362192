<!-- <div class="dialog-wrapper" *ngIf="errorData.msg else remindpassword">
    <div class="dialog-container" fxLayoutAlign="space-between center" fxLayout="column">
        <div class="w-100">
            <h2 *ngIf="errorData.title">{{errorData.title}}</h2>
            <p>
                {{errorData.msg}}
            </p>
        </div>
        <div class="footer" fxLayout="row" fxLayoutAlign="center center">
            <div class="buttons" fxLayoutAlign="center center">
                <button mat-flat-button color="warn" (click)="closeDialog()" type='button'>close dialog</button>
            </div>
        </div>
    </div>
    <app-close-dialog-button (closeEvent)="closeDialog()"></app-close-dialog-button>
</div>


<ng-template #remindpassword>
    <div class="dialog-wrapper">
        <div class="dialog-container" fxLayoutAlign="space-between center" fxLayout="column">
            <div class="w-100">
                <h2 i18n>Sorry, something went wrong.</h2>
                <p i18n>Please try again later or contact support.</p>
            </div>
            <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                <div class="buttons" fxLayoutAlign="center center">
                    <button mat-flat-button color="warn" (click)="closeDialog()" type='button'>close dialog</button>
                </div>
            </div>
        </div>
        <app-close-dialog-button (closeEvent)="closeDialog()"></app-close-dialog-button>
    </div>
</ng-template> -->

<div class="dialog-wrapper">
    <div class="dialog-header">
        <ng-container *ngIf="errorData.title else errorDataTitle">
            <h2>{{errorData.title}}</h2>
        </ng-container>

        <ng-template #errorDataTitle>
            <h2 i18n>Sorry, something went wrong.</h2>
        </ng-template>

        <app-close-dialog-button (closeEvent)="closeDialog()"></app-close-dialog-button>
    </div>

    <mat-divider></mat-divider>

    <div class="dialog-body">
        <ng-container *ngIf="!errorData.msg else errorDataMsg">
            <p style="text-align: left;" i18n>Please try again later or contact support.</p>
        </ng-container>

        <ng-template #errorDataMsg>
            <p style="text-align: left;" i18n>
                {{errorData.msg}}
            </p>
        </ng-template>
    </div>
</div>