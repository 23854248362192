<div class="auth-content">
    <mat-divider></mat-divider>
  
    <div class="auth-body">
        <!-- <div class="image-box">
            <img src="assets/images/lemon-padlock-1.png">
        </div> -->

        <h2 style="width: 100%; text-align: left; padding-top: 8px;" i18n>Don't worry :)</h2>
        <p style="width: 100%; text-align: left;" i18n>Resetting your password only takes a few seconds.</p>
    
    </div>

    <div class="auth-footer">
        <button round-button size="large" filled="filled" color="accent" type='button' style="text-transform: uppercase;" (click)="lostPassword()" i18n>new password</button>
        <button round-button size="large" color="accent" type='button' style="text-transform: uppercase;" (click)="contactSupport()" i18n>contact support</button>
    </div>
</div>