import { Component, EventEmitter, Inject, LOCALE_ID, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';


// import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { LoginEmailService } from 'src/app/services/auth/login/login-email.service';
import { filter, pairwise, take } from 'rxjs/operators';
import { BasePageComponentWithDialogs } from '../../base-page/base-page.component';
import { UserInfo } from 'src/app/services/auth/login/login.models';
import { MatDialog } from '@angular/material/dialog';
import { WrongPasswordDialogComponent } from './wrong-password/wrong-password-dialog/wrong-password-dialog.component';
import { DESKTOP_LOGIN, DialogConfig, FULL_DIALOG_CONFIG, MIX_DIALOG_CONFIG } from '../../dialog/dialog.config';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { lostPasswordTrx, signUpTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { Apollo, gql } from 'apollo-angular';
import { InAppPageParams } from 'src/app/apollo/models/base-models';
import { LICENCE } from 'src/app/app-routing.module';

const LOGIN_SEO_INFO = gql`query LoginSeoQuery($locale: Locale!, $licenceName: Licence!){
  inAppPagesParams(where: {AND: {siteName:"login", OR: [{licence_contains_some: [all,$licenceName]}] }}){
    seo(locales: [$locale, en]) {
          metaTitle
          metaDescription
          relCanonical
    }
  }
 }`

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BasePageComponentWithDialogs {

  formSubmited: boolean = false;

  loginResponse: UserInfo;
  hidePassword = true;
  dialogSize: DialogConfig = this.detectDesktopService.isDesktop() ? DESKTOP_LOGIN : MIX_DIALOG_CONFIG;
  singnUrl: string = this.translationConfig.getTranslation(signUpTrx);
  lostPasswordUrl: string = this.translationConfig.getTranslation(lostPasswordTrx);
  desktopErrorText: string;
  isError: boolean = false;
  goBackUrl: string;
  loginForm = this.formBuilder.group({
    login: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required]
  });

  @Output() closeEvent = new EventEmitter();

  closeDialog(url: string): void {
    this.closeEvent.next(url);
  }

  constructor(
    public dialog: MatDialog,
    protected loginService: LoginEmailService,
    private apollo: Apollo,
    protected translationConfig: TranslationConfig,
    public formBuilder: UntypedFormBuilder,
    protected detectDesktopService: DetectDeviceService,
    public errorDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    protected licenceService: LicenceService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public licence: string,
  ) {
    super(errorDialog, detectDesktopService)
  }

  ngOnInit() {
    this.apollo
      .watchQuery<{ inAppPagesParams: InAppPageParams[] }>({
        query: LOGIN_SEO_INFO,
        variables: {
          locale: this.locale,
          licenceName: this.licence,
        }
      })
      .valueChanges.pipe(
        take(1),
      ).subscribe((response) => {
        const inAppparams = response.data.inAppPagesParams.filter(item => !!item.licence?.find(licence => licence === this.licence))[0] || response.data.inAppPagesParams[0]
        this.setSEOTags(inAppparams?.seo?.metaTitle, inAppparams?.seo?.metaDescription, inAppparams?.seo?.relCanonical)
      });
  }

  onCreateAccountRequested() {

  }

  openLostPasswordDialog(msg?: string): void {
    const dialogRef = this.dialog.open(WrongPasswordDialogComponent, {
      ...this.dialogSize,
      backdropClass: "blur-backdrop",
      panelClass: ["transparent", "auth-card-container"],
      data: { msg: msg }
    });

    dialogRef.afterClosed().subscribe(result => {
      // if (dialogRef.componentInstance.redirectUrl) {
      //   this.closeDialog(dialogRef.componentInstance.redirectUrl);
      // }
    });
  }


  onSubmit(loginForm) {
    if (this.formSubmited) return;
    if (this.loginForm.controls.login.errors) return;
    this.formSubmited = true;
    this.loginService.requestLoginUser(loginForm).pipe(take(1)).subscribe((resp) => {
      this.loginResponse = resp;
      this.loginSuccessfull();
      this.isError = false;
    }, err => {
      console.log("login error", err);
      this.formSubmited = false;
      this.desktopError(err);
    });
  }

  moibleError(err) {
    if (err.status === 406) {
      if (err.error.remind_label && err.error.remind_label === "Reset password") {
        this.openLostPasswordDialog();
      }
      else {
        console.log(err.error.error_message)
        this.openErrorDialog(err.error.error_message, null);
      }
    }
  }

  desktopError(err) {
    if (err.status === 406) {
      if (err.error.remind_label && err.error.remind_label === "Reset password") {
        this.openLostPasswordDialog();
      }
      else {
        console.log(err.error.error_message)
        // this.openErrorDialog(err.error.error_message, null);
        this.desktopErrorText = err.error.error_message;
        this.isError = true;
      }
    }
  }

  loginSuccessfull() {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        console.log('previous url', events[0].urlAfterRedirects);
        console.log('current url', events[1].urlAfterRedirects);
      });
    this.goBackUrl = this.route.snapshot.queryParams['redirectTo'];
    // this.closeDialog('../' + this.goBackUrl);
    if (this.goBackUrl)
      this.closeDialog(this.goBackUrl);
    else this.closeDialog('/')
  }

  signup() {
    this.closeDialog('../' + this.singnUrl);
  }

  lostPassword() {
    this.closeDialog('/' + this.lostPasswordUrl);
  }

}
