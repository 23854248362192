import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { gameTrx, liveCasinoTrx } from 'src/app/router-translation.labels';
import { ActivatedRoute, Router } from '@angular/router';
import { GameCategory } from '../../../apollo/models/base-models';
import { getMappedCategoryLink } from '../../../router-mapping';
import { DialogClosePreviousUrl } from 'src/app/services/utils/dialog-close-previouse-url.service';
import { LicenceService } from 'src/app/services/utils/licence.service';

type CategoryWithLink = GameCategory & { link: string };

@Component({
  selector: 'app-game-category-short',
  templateUrl: './game-category-short.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./game-category-short.component.scss']
})
export class GameCategoryShortComponent extends BasePageComponent {

  constructor(
    protected route: ActivatedRoute,
    protected translationConfig: TranslationConfig,
    private router: Router,
    protected licenceService: LicenceService,
    private prevUrlService: DialogClosePreviousUrl,
  ) { super() }

  public readonly gameTrx: string = this.translationConfig.getTranslation(gameTrx);

  @Input()
  set data(dataInput: any) {
    if (!this._data) {
      this._data = dataInput?.map((category: GameCategory) => {
        return {
          ...category,
          link: this.onCategoryClick(category)
        };

      });
    }

  }

  get data(): any { return this._data; }

  ngOnInit(): void {
  }

  routerClicked() {
    this.prevUrlService.reset();
  }

  onCategoryClick(category: GameCategory) {
    if (this.router.url.includes(this.translationConfig.getTranslation(liveCasinoTrx)) ||
      category.name === this.translationConfig.getTranslation(this.translationConfig.getTranslation(liveCasinoTrx))) {
      return [getMappedCategoryLink(category, this.translationConfig, false)];
    }
    const mapping = [getMappedCategoryLink(category, this.translationConfig)]
    return mapping;
  }
}
