import { Component } from '@angular/core';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';
import { PaymentHistoryService } from 'src/app/services/payment/history/payment-history.service';
import { BasePageComponent } from '../../base-page/base-page.component';
import { PaymentHistory, PaymentHistoryItem } from 'src/app/services/payment/history/history.models';
import { Router } from '@angular/router';
import { pendingWithdrawalTrx } from 'src/app/router-translation.labels';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent extends BasePageComponent {

  constructor(
    public toggleBackService: ToggleMenuBackService,
    public paymentHistoryService: PaymentHistoryService,
    public router: Router,
    public translationConfig: TranslationConfig,
    protected licenceService: LicenceService,
    public detectDeviceService: DetectDeviceService

  ) { super() }

  paymentHistoryResponse: PaymentHistory;
  pageData: PaymentHistoryItem[];
  itemsCount: number;

  isDeposit: boolean = true;
  isDesktop: boolean = this.detectDeviceService.isDesktop();
  isLoading: boolean = true;
  depositUrl: string = "history-payment";
  hasNextPage: boolean = false;
  hasPreviousPage: boolean = false;

  ngOnInit(): void {
    this.isLoading = true;
    this.toggleBackService.showBack();
    this.requestHistory(null);
  }

  requestHistory(historyUrl: string): void {
    this.paymentHistoryService.requestHistory(this.currentPage, historyUrl, this.isDeposit).pipe(takeUntil(this.unsubscribe)).subscribe((resp) => {
      this.paymentHistoryResponse = resp;
      this.pageData = resp.results;
      this.hasNextPage = resp.next !== null;
      this.hasPreviousPage = resp.previous !== null;
      this.itemsCount = resp.count;
      this.isLoading = false;
    })
  }

  paymentAction(item) {
    if (item.transaction_type === "W" && item.status === '1') {
      this.router.navigate(['/' + this.translationConfig.getTranslation(pendingWithdrawalTrx)]);
    }
  }

  nextPage() {
    if (this.currentPage < this.itemsCount) {
      this.currentPage++;
      this.requestHistory(this.paymentHistoryResponse.next);
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.requestHistory(this.paymentHistoryResponse.previous);
    }
  }

  getIconName(transaction): string {
    var icon = `icon-${transaction.transaction_type === 'D' ? 'in' : 'out'}`;

    switch (transaction.status) {
      case '0':
        icon += '-pending';
        break;
      case '1':
        icon += '-pending';
        break;
      case '2':
        icon += '-ok';
        break;
      case '3':
        icon += '-failed';
        break;
      case '4':
        icon += '-failed';
        break;
      default:
        icon += '-ok';
    }
    return icon;
  }

  switchToDeposit() {
    if(!this.isDeposit){
      this.isDeposit = true;
      this.currentPage = 1;
      this.requestHistory(null);
    }
  }

  switchToWithdrawal() {
    if(this.isDeposit){
      this.isLoading = true;
      this.isDeposit = false;
      this.currentPage = 1;
      this.requestHistory(null);
    }
  }
}
