import { Component, Input } from '@angular/core';
import { GameCategory, GameItem } from 'src/app/apollo/models/base-models';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { BasePageComponent } from '../../base-page/base-page.component';
import { LicenceService } from 'src/app/services/utils/licence.service';

@Component({
  selector: 'app-dynamic-category',
  templateUrl: './dynamic-category.component.html',
  styleUrls: ['./dynamic-category.component.scss']
})
export class DynamicCategoryComponent extends BasePageComponent {

  constructor(
    private detectDeviceService: DetectDeviceService,
    protected licenceService: LicenceService
  ) { super() }

  private _games: GameItem[];

  @Input() categoryName: string
  @Input() isLoading: boolean = false;
  @Input() set games(games: GameItem[]) {
    this._games = games;
  }
  get games(): GameItem[] {
    return this._games;
  }

  @Input() linkUrl: string;
  @Input() showMore: boolean = true;
  @Input() rowsCount?: number = null;
  gameCategory: GameCategory;

  ngOnInit(): void {
    this.isDesktop = this.detectDeviceService.isDesktop();
    let gameCategory: GameCategory = {
      id: this.categoryName,
      name: this.categoryName,
      displayName: this.categoryName,
      game: this.games,
      displayType: 'square',
    }

    if (this.rowsCount) {
      gameCategory.numberGameRows = this.rowsCount;
    }

    this.gameCategory = gameCategory;
  }

}
