<div *ngIf="hasPrizes else noprizes">
    <img class="box" src="{{licenceService.getKey('icon-profile-info')}}" />
</div>

<ng-template #noprizes>
    <div class="item" fxLayout="column" fxLayoutAlign="start center">
        <div class="thumbnail">
            <img src="{{licenceService.getKey('icon-avatar')}}" />
        </div>
        <div class="name" i18n>profile</div>
    </div>
</ng-template>