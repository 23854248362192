<!-- <div class="login-form">
    <div>
        <form name="loginForm" fxLayout="row" fxLayoutAlign="start center" [formGroup]="loginForm"
            (ngSubmit)="onSubmit(loginForm)">
            <div class="login-field-container" fxLayoutAlign="start center" fxLayoutGap="5px">
                <div class="top-login-field">
                    <input type="text" formControlName="login" placeholder="{{loginLabel}}" />
                </div>
                <div class="top-login-field">
                    <input [type]="hidePassword ? 'password' : 'text'" placeholder="{{passwordLabel}}" formControlName="password"/>
                </div>
                <button mat-stroked-button class="mat-white" type="submit" i18n>log in</button>
                <button mat-flat-button  color="accent" [routerLink]="['/'+singupUrl]" routerLinkActive="router-link-active" class="mat-primary-font"   type="button" i18n>open account</button>
            </div>
        </form>
    </div>
</div> -->
<div class="auth-menu">
    <button round-button size="large" color="warn" class="btn-login" (click)="login()" i18n>Log in</button>
    <button round-button size="large" filled="filled" color="accent" class="btn-open-account" i18n (click)="signup()">Open Account</button>
</div>