import { Location } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { HeadManipulateService } from 'src/app/services/utils/head-manipulate.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  protected unsubscribe: Subject<void> = new Subject();
  protected titleService: Title;
  protected meta: Meta;
  private updateHeaderService: HeadManipulateService;
  private _route: Location;
  constructor(
  ) {
    this.titleService = inject(Title);
    this.meta = inject(Meta);
    this.updateHeaderService = inject(HeadManipulateService);
    this._route = inject(Location);
  }

  ngOnInit(): void {
  }

  protected setSEOTags(title?: string, description?: string, relCanonical?: string, image?: string, noIndex?: boolean) {

    if (!!title) {
      this.titleService.setTitle(title);
    }
    else {
      this.titleService.setTitle($localize`:@@title-front-page-text:Fireball Casino - the best Online Slots and Live Casino Games`);
    }
    if (!!description) {
      this.meta.updateTag({
        name: 'description', content: description,
      });
    }
    else {
      this.meta.updateTag({
        name: 'description', content: $localize`:@@this.meta-front-page:Join Fireball Casino, a wild online casino! Online slots, table games, Live casino, and arcade games! You find all of them in Fireball Casino. Every week new bonuses and benefits for loyal players such as Cashback, Spin and Win, and Fireball Scratch Cards!`,
      })
    }

    if (!!relCanonical) {
      this.updateHeaderService.removeLinks();
      this.updateHeaderService.createCanonicalNode(relCanonical);
    } else {
      this.updateHeaderService.removeLinks();
      const url = this._route.path().replace(/^\/+|\/+$/g, '');
      this.updateHeaderService.createCanonicalNode(url);
    }

    if (!!noIndex) {
      this.meta.addTag({ name: 'robots', content: 'noindex' });
    }
    else {
      this.meta.removeTag('name=robots');
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
