import { Apollo } from 'apollo-angular';
import { AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, NgZone, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from '../../base/base.component';

import { FRONT_PAGE_GAME_DESCRIPTION } from 'src/app/apollo/front-page-games/front-page-games';
import { GameItem, GameResponse } from 'src/app/apollo/models/base-models';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BalanceStatusService } from 'src/app/services/wallet/balance-status.service';
import { Observable } from 'rxjs';
import { BalanceInAppInfo } from 'src/app/services/wallet/wallet.models';
import { depositTrx, launchGameTrx, loginTrx, paymentTrx, signUpTrx } from 'src/app/router-translation.labels';
import { FULL_DIALOG_CONFIG } from '../dialog.config';
import { BasePageComponent } from '../../base-page/base-page.component';
import { LoaderService } from 'src/app/services/utils/loader/loader.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { DialogClosePreviousUrl } from 'src/app/services/utils/dialog-close-previouse-url.service';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { ClonerService } from 'src/app/services/utils/clone-object.service';
import * as PixiSpine from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TextureAtlas } from "pixi-spine";
import { SkeletonJson, AtlasAttachmentLoader } from '@pixi-spine/runtime-4.0';
import { extensions, ExtensionType, Texture } from 'pixi.js';
import { Subject, of, timer } from 'rxjs';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { GamificationLevelService } from 'src/app/services/profile/gamification-level.service';
import { ComponentComunicationService, GAME_ACTION_LAUNCH } from 'src/app/services/communication/component-comunication.service';
import { WINDOW } from '@ng-web-apis/common';

const ANIMATION_CANVAS_SIZE = 194;
const ANIMATION_NAME = 'animation';

interface LaunchGameCloseDialog {
  isClosedByLaunchGame: boolean;
  redirectUrl: string;
}

export interface DialogData {
  gameName: string;
  gameId: string;
  thumbnail: string;
  tag: string[];
  url: string;
  id: string;
  background: string
}

@Component({
  template: ''
})
export class GameDialogRouteComponent extends BasePageComponent {
  constructor(public dialog: MatDialog,
    private router: Router,
    private apollo: Apollo,
    private sanitizer: DomSanitizer,
    private loaderService: LoaderService,
    private returnUrlService: DialogClosePreviousUrl,
    private cloneService: ClonerService,
    private licenseService: LicenceService,
    @Inject(LOCALE_ID) public locale: string,
    private route: ActivatedRoute) { super() }


  ngOnInit(): void {
    this.loaderService.show();
    this.route.paramMap.pipe(
      take(1),
      switchMap((params: ParamMap) => {
        const gameName = params.get('game').split('_').join(' ');
        return this.apollo
          .watchQuery<GameResponse>({
            query: FRONT_PAGE_GAME_DESCRIPTION,
            variables: {
              gameName: gameName,
              locale: this.locale
            }
          })
          .valueChanges
      }),
      take(1),
      takeUntil(this.unsubscribe)
    ).subscribe((response) => {
      if (!response.data?.game) {
        this.openDialog(null);
      }
      else {
        const pageJson: GameItem = this.cloneService.deepClone(response.data.game);
        if (pageJson.shortDescription) {
          pageJson.shortDescription.safe = this.sanitizer.bypassSecurityTrustHtml(pageJson.shortDescription.html);
        }
        if (pageJson.name) this.titleService.setTitle(pageJson.name);
        const slotName = pageJson.name;
        const title = !!pageJson.seo?.metaTitle ? pageJson.seo.metaTitle : $localize`:@@title-game-page-meta:${slotName} Slot Game | Play ${slotName} Slot Demo Online`;
        const casinoName = this.licenseService.getKey("name");
        const description = !!pageJson.seo?.metaDescription
          ? pageJson.seo.metaDescription
          : $localize`:@@description-game-page-meta:Popular slot ${slotName} already available at ${casinoName}. Play ${slotName} slot demo for free, register today to get your welcome bonus.`;
        this.setSEOTags(title, description);


        // this.gtag?.event(`game opened: ${pageJson.name}`);
        if (this.dialog.openDialogs.length == 0)
          this.openDialog(pageJson);
      }
      this.loaderService.hide();

    }, (error) => {
      this.openDialog(null);
    });


  }

  openDialog(game: GameItem): void {
    const dialogRef = this.dialog.open(GameDialogComponent, {
      ...FULL_DIALOG_CONFIG,
      panelClass: "game-dialog",
      data: game
    });



    dialogRef.afterClosed().pipe(
      switchMap((result: LaunchGameCloseDialog) => {

        return this.returnUrlService.getUrl()
      }),
      takeUntil(this.unsubscribe)
    ).subscribe(url => {
      // if (dialogRef.componentInstance.redirectUrl) this.router.navigate([dialogRef.componentInstance.redirectUrl], { relativeTo: this.route });
      if (url) this.router.navigate(["", ...url]);
      else this.router.navigate(["/"]);
    });
  }
}


@Component({
  selector: 'app-game-dialog',
  templateUrl: './game-dialog.component.html',
  styleUrls: ['./game-dialog.component.scss']
})
export class GameDialogComponent extends BaseComponent implements AfterViewInit {
  app: PIXI.Application;
  isServer: boolean;
  animated: boolean = false;
  canvasHeight: number = ANIMATION_CANVAS_SIZE;
  canvasWidth: number = ANIMATION_CANVAS_SIZE;
  animationReady: boolean = false;
  protected _unsubscribe: Subject<void> = new Subject();

  @ViewChild('pixiAnimation') pixiAnimation: ElementRef;
  @ViewChild('gamedialogwrapper') gameDialogWrapper: ElementRef;

  balanceObs$: Observable<BalanceInAppInfo>;
  constructor(
    public dialogRef: MatDialogRef<GameDialogComponent>,
    private router: Router,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId,
    private balanceService: BalanceStatusService,
    protected translationConfig: TranslationConfig,
    public minimizeSiteService: MinimizeSiteService,
    private returnUrlService: DialogClosePreviousUrl,
    protected licenceService: LicenceService,
    private gamificationLevelService: GamificationLevelService,
    private communicationService: ComponentComunicationService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: GameItem) { super() }

  paymentUrl: string = this.translationConfig.getTranslation(paymentTrx) + '/' + this.translationConfig.getTranslation(depositTrx);
  // public redirectUrl: string = null;

  closeDialog(closeData?: LaunchGameCloseDialog): void {
    // this.redirectUrl = redirectUrl;
    // this.router.navigate(["/"])
    this.dialogRef.close(closeData);
    this.document.body.classList.remove("cdk-global-scrollblock");

  }
  closeDialogReturn(): void {
    this.returnUrlService.getUrl().pipe(take(1)).subscribe((url) => {
      if (url) {
        this.router.navigate(url)
      }
    });
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.isServer = isPlatformServer(this.platformId);
    this.animated = (this.data.animation && this.data.animation.length > 0);
    this.balanceObs$ = this.balanceService.getBalanceInfoStatus();
  }

  ngAfterViewInit() {
    // this.renderer.setStyle(this.gameDialogWrapper.nativeElement, 'background-image', 'linear-gradient(to bottom, black 0%, #434343 100%), url(https://d205654a3b2af1b75209-275b861a8577e42fdaf34f4c14f5e708.ssl.cf3.rackcdn.com/g1/netent/Starburst/theme-starburst-opt.jpg)');

    if (this.animated && !this.isServer) {
      this.ngZone.runOutsideAngular(() => {
        timer(0, 100).pipe(
          switchMap(() => this.initializePixi()),
          takeUntil(this._unsubscribe)
        ).subscribe();
      });
    }
  }

  launchGame() {
    const closeData: LaunchGameCloseDialog = { isClosedByLaunchGame: true, redirectUrl: null };
    this.communicationService.postMessage(GAME_ACTION_LAUNCH);
    this.router.navigate([this.translationConfig.getTranslation(launchGameTrx), this.data.gameid], {
      queryParams: { gameName: this.data.systemName },
      skipLocationChange: true
    }).then(() => {

      this.closeDialog(closeData);
    });

    // this.router.navigate(['/'+launcgGameTrx+'/'+this.data.gameid]);
  }

  signUp() {
    this.closeDialog();
    this.router.navigate([this.translationConfig.getTranslation(signUpTrx)]);
  }

  login() {
    this.closeDialog();
    this.router.navigate([this.translationConfig.getTranslation(loginTrx)], { queryParams: { redirectTo: this.router.url } });
  }

  deposit() {
    this.closeDialog();
    this.router.navigate([this.paymentUrl]);
  }

  initializePixi(): Observable<void> {
    if (this.pixiAnimation && this.pixiAnimation.nativeElement) {
      this.app = new PIXI.Application({
        preserveDrawingBuffer: true,
        backgroundAlpha: 0,
        width: this.canvasWidth,
        height: this.canvasHeight,
      });

      const assets = {
        spine: {
          atlas: null,
          texture: null,
          json: null
        }
      }

      this.initializeAssets(this.httpClient, assets).then(() => {
        this.loadSpine(assets).then((res) => {
          this.onAssetsLoaded(res);
        });
      });

      this._unsubscribe.next();
      this._unsubscribe.complete();
    }
    return of(null);
  }

  async loadSpine(assets) {
    const imageDelivery = {
      extension: ExtensionType.LoadParser,
      test: (url) => url.startsWith('https://media.graphassets.com'),
      async load(src) {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.crossOrigin = 'anonymous'
          img.onload = () => resolve(Texture.from(img))
          img.onerror = reject
          img.src = src
        })
      },
    }

    extensions.add(imageDelivery)

    const spine = assets.spine;

    if (PIXI.Assets.cache.has(spine.texture)) {
      spine.texture = PIXI.Assets.cache.get(spine.texture);
    } else {
      spine.texture = await PIXI.Assets.load({
        src: spine.texture,
        format: 'image'
      })
    }

    const textureAtlas: TextureAtlas = await new Promise(resolve =>
      new TextureAtlas(assets.spine.atlas, (_line, callback) => callback(spine.texture), resolve));

    const spineAtlasLoader = new AtlasAttachmentLoader(textureAtlas);
    const parser = new SkeletonJson(spineAtlasLoader);
    const spineData = parser.readSkeletonData(spine.json);

    return spineData;
  }

  async initializeAssets(httpClient, assets) {
    assets.spine.atlas = await httpClient.get(this.data.animation.find(item => item.fileName.endsWith('.atlas')).url, { responseType: 'text' }).toPromise();
    assets.spine.texture = this.data.animation.find(item => item.fileName.endsWith('.png')).url;
    assets.spine.json = await httpClient.get(this.data.animation.find(item => item.fileName.endsWith('.json')).url, { responseType: 'json' }).toPromise();
  }

  onAssetsLoaded(spineData) {
    const thumbnailSpine = new PixiSpine.Spine(spineData);

    thumbnailSpine.state.data.skeletonData.bones;
    thumbnailSpine.scale.x = Math.round((this.app.screen.width / thumbnailSpine.width) * 100) / 100;
    thumbnailSpine.scale.y = Math.round((this.app.screen.height / thumbnailSpine.height) * 100) / 100;
    thumbnailSpine.x = this.app.screen.width / 2;
    thumbnailSpine.y = this.app.screen.height / 2;

    thumbnailSpine.state.setAnimation(0, ANIMATION_NAME, true);

    if (this.app && this.app.stage) {
      this.app.stage.addChild(thumbnailSpine);
    }

    if (this.pixiAnimation && this.pixiAnimation.nativeElement && this.app && this.app.view) {
      this.pixiAnimation.nativeElement.appendChild(this.app.view);
    }

    this.animationReady = true;
  };


}
