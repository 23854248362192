import { Apollo, gql } from 'apollo-angular';
import { AfterViewInit, Component, Inject, LOCALE_ID, PLATFORM_ID, effect } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { ExpansionPanelItem, GameCategory, GameCategoryResponse, GameItem } from 'src/app/apollo/models/base-models';
import { BaseGamePage } from '../ase-game-page.component';
import { MatDialog } from '@angular/material/dialog';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { LICENCE } from 'src/app/app-routing.module';
import { FilterGamesService } from 'src/app/services/utils/filter-games.service';
import { isPlatformServer } from '@angular/common';
import { TranslationConfig } from '../../../utils/translate-config';
import { ClonerService } from 'src/app/services/utils/clone-object.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { combineLatest, timer } from 'rxjs';
import { PopularGamesService } from 'src/app/services/profile/popular-games.service';
import { MostPlayedGameStatistic } from 'src/app/services/profile/profile.models';
import { environment } from 'src/environments/environment';
import { DialogClosePreviousUrl } from 'src/app/services/utils/dialog-close-previouse-url.service';
import { WINDOW } from '@ng-web-apis/common';

const STEP_SIZE = 80;

export const GAME_CATEGORY_PAGE_QUERY = gql`query GameCategory($locale: Locale!,$categoryName: String!,$skipNumber: String,$licenseName: Licence){
  gameCategories(locales: [$locale, en],where: {name: $categoryName}) {
    displayName
    displayType
    gameType
    icon{
        url
    }
    seo {
      metaTitle
      metaDescription
    }
    expansionPanel{
      item{
        title
        content{
          html
        }
      }
    }
    game(where:{OR: [{ licence: $licenseName }, { licence: all }]},first: ${STEP_SIZE}, after: $skipNumber) {
      name
      id
      gameid
      systemName
      hideGame
      tag
      thumbnail {
        url(transformation: {
          image: { resize: { width: 206, height: 206, fit: clip } }
        })
      }
      thumbnailDesktop{
        url(transformation: {
          image: { resize: { width: 200, height: 200, fit: clip } }
          document: { output: { format: webp } }
        })
      }
      thumbnailDesktopSquare{
        url(transformation: {
          image: { resize: { width: 194, height: 194, fit: clip } }
          document: { output: { format: webp } }
        })
      }
      thumbnailDesktopRectangular{
        url(transformation: {
          image: { resize: { width: 236, height: 177, fit: clip } }
          document: { output: { format: webp } }
        })
      }
      initialLoadColor {
        css
      }
      gameProvider{
        name
        providerExclusionTerritories{
          countryCode
          state
        }
      }
      animation{
        url
        fileName
      }
    }
  }
}
`;

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.scss']
})
export class CategoryPageComponent extends BaseGamePage implements AfterViewInit {
  mostPlayedGamesStatistics: MostPlayedGameStatistic[] = [];
  constructor(
    protected route: ActivatedRoute,
    protected translationConfig: TranslationConfig,
    protected apollo: Apollo,
    public dialog: MatDialog,
    protected toggleBackService: ToggleMenuBackService,
    public detectDeviceService: DetectDeviceService,
    private filterGamesService: FilterGamesService,
    public minimizeSiteService: MinimizeSiteService,
    private cloneService: ClonerService,
    private sanitizer: DomSanitizer,
    private loginStatusService: LoginStatusService,
    private popularGamesService: PopularGamesService,
    private prevUrlService: DialogClosePreviousUrl,

    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public licence: string,
    @Inject(PLATFORM_ID) protected platformId,
    @Inject(WINDOW) protected windowRef: Window,
    protected router: Router) {
    super(dialog);
    if (!isPlatformServer(this.platformId)) {
      effect(() => {
        this.mostPlayedGamesStatistics = this.popularGamesService.popularGames();
        this.addGamesStatistics();
      });
    }
  }
  ngAfterViewInit(): void {
    timer(300).pipe(take(1)).subscribe(() => {
      this.windowRef.scrollTo(0, this.prevUrlService.getScrollPosition());
    })
  }

  categories: GameCategory[];
  gameCategory: string;
  flexValue = 33;
  categoryName: string;
  games: GameItem[] = [];
  afterID: string = null;
  desktopSize: string;
  category: GameCategory;
  isCategoryImageLoaded = false;
  isLoading = false;
  expansionPanel: ExpansionPanelItem[];
  isLoggedIn = false;
  gameType: string = 'slots';


  getData(): void {
    this.isDesktop = this.detectDeviceService.isDesktop();
    this.toggleBackService.showBack();
    this.loginStatusService.getIfUserLogged().pipe(take(1)).subscribe((isLogged) => {
      this.isLoggedIn = true;
    })

    this.flexValue = this.isDesktop ? 25 : 33;

    combineLatest({
      params: this.route.paramMap.pipe(
        takeUntil(this.unsubscribe)),
      data: this.route.data.pipe(
        take(1)),
    }
    ).subscribe((resp) => {
      this.afterID = null;
      this.games = [];

      this.gameCategory = resp.params.get('category') ?? this.route.snapshot.data.category;
      this.gameType = resp.data?.gameType || null
      this.getGames(true);
    });


    if (this.isDesktop) {
      this.apollo
        .watchQuery<GameCategoryResponse>({
          query: GAME_CATEGORY_PAGE_QUERY,
          variables: {
            categoryName: this.gameCategory,
            locale: this.locale,
            skipNumber: this.afterID,
            licenseName: this.licence
          }
        })
        .valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
          const categories = [this.filterGamesService.filterCategory(response.data.gameCategories[0], this.gameType)];

        });
    }
  }

  ngOnInit(): void {
    this.getData();
  }

  getGames(initial: boolean = false) {
    this.isLoading = initial && !isPlatformServer(this.platformId);

    this.apollo.watchQuery<GameCategoryResponse>({
      query: GAME_CATEGORY_PAGE_QUERY,
      variables: {
        categoryName: this.gameCategory,
        locale: this.locale,
        skipNumber: this.afterID,
        licenseName: this.licence
      }
    }).valueChanges
      .pipe(
        take(1),
      )
      .subscribe({
        next: (resp) => {
          const gameCategory = resp.data.gameCategories[0];
          const category = this.filterGamesService.filterCategory(gameCategory, this.gameType);
          if (initial) {
            this.isLoading = false;
            this.category = category;
            this.categoryName = gameCategory.displayName;
            this.desktopSize = gameCategory.displayType;

            const expansionItems: ExpansionPanelItem[] = this.cloneService.deepClone(gameCategory.expansionPanel?.item);

            this.expansionPanel = expansionItems?.map(item => {
              if (!!item && !!item.content) {
                item.content.safehtml = this.sanitizer.bypassSecurityTrustHtml(item.content.html);
                return item;
              }
            });

            const title = !!gameCategory.seo?.metaTitle ? gameCategory.seo.metaTitle : `${this.categoryName} Online - Fireball Casino`;
            const description = !!gameCategory.seo?.metaDescription
              ? gameCategory.seo.metaDescription
              : $localize`:@@description-category-page: Play ${this.categoryName}:categoryName: online at Fireball Casino for free or real money. Fireball Casino offers a wide range of Slot games to play online,  Slot games tournaments and bonuses.`;
            this.setSEOTags(title, description)
          }
          if (category?.game.length > 0 && category.game[category.game.length - 1].id !== this.afterID) {
            let categoryGames: GameItem[] = this.cloneService.deepClone(category.game);
            this.games = [...this.games, ...categoryGames];
            this.afterID = this.games[this.games.length - 1].id;
            this.addGamesStatistics();
          }
        }, error: (error) => {
          if (initial)
            this.router.navigate(['/not-found']);
        }
      });
  }

  onScroll() {
    this.getGames();
  }

  addGamesStatistics() {
    if (this.mostPlayedGamesStatistics.length > 0 && this.category && this.category.displayName === "Most Played") {
      this.mostPlayedGamesStatistics.forEach((game) => {
        this.games = Object.assign([], this.games);
        this.games.forEach((gameItem) => {
          if (gameItem.gameid === game.externalId) {
            gameItem.uniquePlayers = game.uniquePlayers * environment.uniquePlayerMultiplier;
          }
        });
      });
    }
  }
}
