<div class="game-category-container">
  <div class="categoryNameContainer" fxLayout="row" fxLayoutAlign="center center">


    <ng-container *ngIf="isAll">
      <app-header-page-compoanent iconName="{{licenceService.getKey('icon-operator')}}" i18n>Top Operators</app-header-page-compoanent>
    </ng-container>
    <ng-container *ngIf="!isAll">
      <h2 fxLayoutAlign="center center" class="category-title">
        <img class="category-icon" src="assets/icons/{{licenceService.getKey('icon-operator')}}">
        <span i18n>Top Operators</span>
      </h2>
    </ng-container>
    <span class="spacer"></span>
    <a *ngIf="!isAll" class="button-all-games" fxLayoutAlign="end center" fxLayout="row" [routerLink]="onProvidersClick()">
      <span i18n>All</span>
      <!-- <div> -->
        <img src="{{licenceService.getKey('icon-right')}}" icon-btn size="large">
      <!-- </div> -->
    </a>

  </div>

  <div class="game-card-wrapper swiper-grid-cards" *ngIf="!isAll && !isServer else allOperators">
    <swiper [config]="config">
      <ng-template *ngFor="let item of operators" swiperSlide>
        <a [routerLink]="['',providerUrl, item.name]">
          <div class="game-card">
            <div class="thumbnail">
              <img *ngIf="item.thumbnailMobile" loading="lazy" alt="{{item.name}}" data-src="{{ item.thumbnailMobile.url }}" class="swiper-lazy" />
            </div>
            <div class="title">
              {{item.displayName}}
            </div>
          </div>
        </a>
      </ng-template>
    </swiper>
  </div>
</div>

<ng-template #allOperators>
  <div class="game-card-wrapper all">
    <div class="game-card" *ngFor="let item of data">
      <a [routerLink]="  ['',providerUrl,item.name]">
        <div class="thumbnail">
          <img *ngIf="item.thumbnailMobile" loading="lazy" alt="{{item.name}}" data-src="{{ item.thumbnailMobile.url }}" class="swiper-lazy" />
        </div>
        <div class="title">
          {{item.displayName}}
        </div>
      </a>
    </div>
  </div>
</ng-template>