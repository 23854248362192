import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, effect } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameItem } from 'src/app/apollo/models/base-models';
import { gameInfoTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { DialogClosePreviousUrl } from 'src/app/services/utils/dialog-close-previouse-url.service';
import { TopGamesService } from 'src/app/services/profile/top-games.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { BasePageComponent } from '../../base-page/base-page.component';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { TopGames } from 'src/app/services/profile/profile.models';
import { WINDOW } from '@ng-web-apis/common';


export type SelectedGameData = {
  name: string;
  thumbnail: {
    url: string;
  }
  thumbnailDesktop: {
    url: string
  }

  tag: string[];
  gameid: string;
  url: string;
  id: string;
  initialLoadColor: string

}
@Component({
  selector: 'app-desktop-game-card',
  templateUrl: './game-card.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./game-card.component.scss']
})
export class DesktopGameCardComponent extends BasePageComponent {

  isServer: boolean;
  topGames: TopGames;

  constructor(
    public detectDeviceService: DetectDeviceService,
    public translationConfig: TranslationConfig,
    private returnUrlService: DialogClosePreviousUrl,
    private route: ActivatedRoute,
    protected licenceService: LicenceService,
    private topGamesService: TopGamesService,
    private router: Router,
    @Inject(WINDOW) readonly windowRef: Window,
  ) {
    effect(() => {
      this.topGames = this.topGamesService.topGames();
      if (this._data) {
        this.checkTopGames();
      }
    });
    super()
  }

  launchGameUrl: string[];
  gameNameSlug: string;
  isWinDropBadge: boolean = false;
  isInTopGamesByMaxWin: boolean = false;
  isInTopGamesByTotalRounds: boolean = false;

  @Output() clickEvent = new EventEmitter<SelectedGameData>();
  @Input() size: string;

  @Input()
  set data(dataInput: GameItem) {
    this._data = dataInput;
    this.gameNameSlug = dataInput.systemName;
    this.launchGameUrl = ["", this.translationConfig.getTranslation(gameInfoTrx), this.gameNameSlug];
    // this.launchGameUrl = ["", gameTrx, gameInfoTrx, this.gameNameSlug];
  }

  get data(): any { return this._data; }

  ngOnInit(): void {
    if (this.data && this.topGames) {
      this.checkTopGames();
    }
    this.size = this.size.charAt(0).toUpperCase() + this.size?.slice(1);
    // this.topGamesService.getStore().pipe().subscribe((data) => {
    //   if (data.maxWin.includes(this.data.gameid)) {
    //     this.isInTopGamesByMaxWin = true;
    //   }

    //   if (data.totalRounds.includes(this.data.gameid)) {
    //     this.isInTopGamesByTotalRounds = true;
    //   }
    // });
  }

  checkTopGames() {
    if (this.data?.gameid) {
      if (this.topGames.maxWin.includes(this.data.gameid)) {
        this.isInTopGamesByMaxWin = true;
      } else {
        if (this.isInTopGamesByMaxWin) {
          this.isInTopGamesByMaxWin = false;
        }
      }
      if (this.topGames.totalRounds.includes(this.data.gameid)) {
        this.isInTopGamesByTotalRounds = true;
      } else {
        if (this.isInTopGamesByTotalRounds) {
          this.isInTopGamesByTotalRounds = false;
        }
      }
    }
  }

  launchGame($event, gameSlug: string) {
    if (this.detectDeviceService.isDesktop()) {
      // this.loaderService.show();

      this.returnUrlService.setUrl(this.router.url.split('/'), this.windowRef.scrollY);

      // this.route.url.pipe(takeUntil(this.unsubscribe)).subscribe(url => {
      //   this.returnUrlService.setUrl(url.map(item => item.toString()), this.windowRef.scrollY);
      // })
    }
    $event.preventDefault();
    // this.router.navigate(["", this.translationConfig.getTranslation(gameTrx), this.translationConfig.getTranslation(gameInfoTrx), gameSlug]).catch(() => {
    //   this.loaderService.hide();
    // })
  }

  badge() {
    if (this.isInTopGamesByMaxWin) {
      return 'big_win';
    } else if (this.isInTopGamesByTotalRounds) {
      return 'hot';
    } else if (this._data?.tag?.includes('drops_win')) {
      return 'drops_win';
    } else if (this._data?.tag?.includes('take_prize')) {
      return 'take_prize';
    } else if (this._data?.tag?.includes('cash_drops')) {
      return 'cash_drops';
    } else {
      return null;
    }
  }

  openGameDialog() {

    this.clickEvent.next(this.data.initialLoadColor.css);
  }

}
