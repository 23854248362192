import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

export const LOGIN_EMAIL_URL: string = "/v1/auth/login/";
export const LOGOUT_URL: string = "/v1/auth/logout/";
export const CHECK_SESSION: string = "/v1/auth/session/check/"
export const USER_INFO_SESSION: string = "/v1/auth/info/"
export const CURRENT_BALANCE: string = "/v1/cashier/balance/"
export const LOST_PASSWORD: string = "/v1/auth/password/reset/"
export const CHANGE_PASSWORD: string = "/v1/auth/password/change/"
export const NEW_PASSWORD: string = "/v1/auth/password/change/force/"
export const LAUNCH_GAME: string = "/v1/games/launch/"
export const EDIT_PROFILE: string = "/v1/auth/profile/edit/"
export const REGISTRATION: string = "/v1/auth/registration/"
export const SMS_VERIFICATION_RESEND_PROMOCODE: string = "/v1/auth/registration/verification/sms/resend-code/"
export const SMS_VERIFICATION_ADD_TELEPHONE: string = "/v1/auth/registration/verification/sms/add-phone/"
export const SMS_VERIFICATION_ADD_TELEPHONE_PROMOCODE: string = "/v1/auth/registration/verification/sms/edit-phone/"
export const SMS_VERIFICATION_VERIFY: string = "/v1/auth/registration/verification/sms/"
export const SMS_VERIFICATION_VERIFY_PROMOCODE: string = "/v1/promotions/code/sms-verification/"
export const BO_BONUS_INFO: string = "/v1/promotions/available/"
export const USER_FULL_DETAILS: string = "/v1/auth/info/"
export const PAYMENT_LIST: string = "/v1/cashier/deposit/"
export const PAYMENT_API: string = "/v1/cashier/deposit/"
export const WITHDRAWAL_API: string = "/v1/cashier/payout/"
export const PENDING_WITHDRAWAL: string = "/v1/cashier/payout/pending/"
export const CANCEL_WITHDRAWAL: string = "/v1/cashier/payout/cancel/"
export const GAME_HISTORY: string = "/v1/games/history/"
export const PAYMENT_HISTORY: string = "/v1/cashier/history/"
export const LAST_PLAYED: string = "/trans/last-played/"
export const CURRENT_LOTTERY_LEVEL: string = "/ui/clientapp/currentlotterylevel"
export const LOTTERY_SELECT_BOX_VOLATILITY: string = "/ui/clientapp/select-prize-volatility-type"
export const FREE_SPINS_QUEUE: string = "/v1/promotions/freespins/queue/"
export const FREE_SPINS_LIST: string = "/v1/promotions/freespins/list/"
export const REDEEM_PROMOCODE: string = "/v1/promotions/use-code/"
export const BONUS_QUEUE: string = "/v1/promotions/bonuses/queue/"
export const BONUS_LIST: string = "/v1/promotions/bonuses/list/"
export const SET_LIMIT: string = "/v1/rg/limits/set/"
export const BONUS_CANCEL: string = "/v1/promotions/bonuses/cancel/"
export const BONUS_QUEUE_REORDER: string = "/v1/promotions/bonuses/queue/reorder/"
export const USER_MESSAGES: string = "/v1/messages/"

export const USER_DOCUMENTS: string = "/v1/kyc/get-documents/"
export const UPLOAD_DOCUMENTS: string = "/v1/kyc/upload-document/"
export const GET_DOCUMENT: string = "/user/get_document/"
export const DOWNLOAD_DOCUMENT: string = "/user/download_document/"

export const USER_CHARGEBACK: string = "/ui/clientapp/activechargeback"
export const CLAIM_USER_CHARGEBACK: string = "/ui/clientapp/claimcashback"
export const USER_GAMIFICATION: string = "/ui/clientapp/gamificationlevel"
export const TOP_USER_GAMES: string = "/ui/clientapp/topusergames"
export const POPULAR_GAMES: string = "/ui/clientapp/populargames"
export const USER_BONUSLOTTERY: string = "/ui/clientapp/currentbonuslottery"
export const USER_LOTTERY_LEVEL_PRIZES: string = "/ui/clientapp/lottery-level-prizes"
export const BEST_GAMES: string = "/ui/clientapp/bestgames"
export const SET_NICKNAME: string = "/ui/clientapp/setnickname"
export const SET_RANDOM_NICKNAME: string = "/ui/clientapp/randomnickname"
export const SET_FIRST_LOGIN: string = "/ui/clientapp/setfirstlogin"
export const UPCOMMING_TOURNAMENTS: string = "/tournaments/upcomming"
export const ACTIVE_TOURNAMENTS: string = "/tournaments/active"
export const REBUY_BONUS_TOURNAMENTS: string = "/tournaments/rebuy-bonus"
export const RECENT_TOURNAMENTS: string = "/tournaments/recently-finished"
export const OPEN_PRIZE_BOX: string = "/ui/clientapp/openprizebox"
export const OPEN_LOTTERY_BOX: string = "/ui/clientapp/openlotterybox"
export const SPIN_WHEEL: string = "/ui/clientapp/spinwheel"
export const WHEEL_INFO: string = "/ui/clientapp/wheelinfo"
export const JACKPOT_VALUES: string = "/ui/clientapp/jackpot-values"
export const RECOMMENDATIONS_OPEN_GAME = "/recommendations/open_game/"
export const RECOMMENDATIONS_OPEN_HOME_PAGE = "/recommendations/home"
export const RECOMMENDATIONS_GET_GAMES = "/recommendations/recommendations"

export const MISSION_ACHIEVEMENTS: string = "/ui/clientapp/missionachievements"

export const CURACAO = "curacao";
export const GAMBIA = "gambia";

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {

  apiUrl: string;

  constructor(protected http: HttpClient, protected cookie: CookieService, @Inject(LOCALE_ID) public locale: string) {

  }

  createHeaders(headers: {
    [name: string]: string | string[];
  } = {}): HttpHeaders {
    const newHeaders = {};
    Object.assign(newHeaders, headers);
    const csrfToken = this.cookie.get('csrftoken');
    newHeaders['Content-language'] = this.locale;
    newHeaders['Accept-Language'] = `pl-PL,${this.locale}`;
    newHeaders['x-translation-lang'] = this.locale?.slice(0, 2);
    newHeaders['Content-Type'] = 'application/x-www-form-urlencoded';
    if (csrfToken) {
      newHeaders['X-CSRFToken'] = csrfToken;
    }
    return new HttpHeaders(newHeaders);
  }

  setApiUrl(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  protected postEmptyRequest<T>(url: string) {
    const httpOptionsDefault = {
      headers: this.createHeaders(),
      withCredentials: true
    };
    return this.http.post<T>(this.apiUrl + url, {}, { ...httpOptionsDefault });
  }

  protected postRequest<T>(url: string, formGroup: UntypedFormGroup) {
    const httpOptionsDefault = {
      headers: this.createHeaders(),
      withCredentials: true
    };
    return this.http.post<T>(this.apiUrl + url, formGroup.value, { ...httpOptionsDefault });
  }



  protected getRequest<T>(url: string, formGroup: UntypedFormGroup) {
    return this.http.get<T>(this.apiUrl + url, { params: formGroup.value });
  }

  protected getRequestParam<T>(url: string, paramName: string, paramValue: string) {
    const options = { params: new HttpParams().set(paramName, paramValue) };
    return this.http.get<T>(this.apiUrl + url, options);
  }


  protected getRequestHttpParams<T>(url: string, reqParams: HttpParams) {
    // const args = { params: reqParams };
    const options = {
      params: reqParams,
      headers: this.createHeaders(),
      withCredentials: true
    };
    return this.http.get<T>(this.apiUrl + url, options);
  }

  protected get<T>(url: string) {
    return this.http.get<T>(this.apiUrl + url);
  }

  protected delete<T>(url: string) {
    return this.http.delete<T>(this.apiUrl + url);
  }

  protected handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    if (error.status === 406) {
      return throwError(
        error);
    }
    else {
      return throwError(
        "Some unexpected error occured.");
    }

  };
}
