<div *ngFor="let category of data; let index = index; let first = first" class="game-category-container" [ngStyle]="{'margin-top': !first ? '13px' : '13px'}">
  <div class="categoryNameContainer" fxLayout="row" fxLayoutAlign="center center">
    <div fxLayoutAlign="center center" class="category-title">
      <img class="category-icon" src="{{category?.icon?.url}}" *ngIf="category.icon!==null else actual">
      <h2>{{category.displayName}}</h2>
    </div>
    <span class="spacer"></span>
    <ng-container [ngTemplateOutlet]="isServer ? serverButtons : sliderButtons" [ngTemplateOutletContext]="{category: category, index: index}"></ng-container>
  </div>
  <app-desktop-front-page-game [categoryName]="category.name" [numberOfRows]="category.numberGameRowsDesktop ?? 1" [size]="category.displayType ?? 'rectangular'" [iteration]="category.displayType==='rectangular'?5:6" [data]="category.game" #frontPageGames></app-desktop-front-page-game>
</div>

<ng-template #actual>
  <img class="category-icon" src="assets/desktop/top-games-icon.svg">
</ng-template>

<ng-template #sliderButtons let-category="category" let-index="index">
  <a class="button-all-games" mat-button color="primary" [routerLink]="onCategoryClick(category)" i18n>See More</a>
  <div class="swiper-navigation">
    <img src="{{licenceService.getKey('icon-right')}}" icon-btn direction="left" (click)="prev(index)">
    <img src="{{licenceService.getKey('icon-right')}}" icon-btn class="button-next" (click)="next(index)">
  </div>
</ng-template>

<ng-template #serverButtons let-category="category">
  <a class="button-all-games" mat-button color="primary" [routerLink]="onCategoryClick(category)" i18n>See More <img src="{{licenceService.getKey('icon-right')}}"></a>
</ng-template>
