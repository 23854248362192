<div class="swiper-wrap">
  <ng-container *ngIf="!isServer else ssrBaner">
    <swiper fxFlex="auto" class="operator-swiper" [config]="config">
      <ng-template swiperSlide *ngFor="let item of categories">
        <div class="card" (click)="openCategory(item)">
          <div class="rectangle">
            <div class="img-wrap">
              <img src="{{item.thumbnail?.url}}" alt="{{item.name}}" />
            </div>
            <div class="name">
              {{item.displayName}}
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </ng-container>
</div>

<ng-template #ssrBaner>
  <div fxLayoutAlign="space-evenly center" fxLayout="row">
    <div *ngFor="let item of categories?.slice(0,4)" class="card" (click)="openCategory(item)">
      <div class="rectangle">
        <div class="img-wrap">
          <img src="{{item.thumbnail?.url}}" alt="{{item.name}}" />
        </div>
        <div class="name">
          {{item.displayName}}
        </div>
      </div>
    </div>
  </div>
</ng-template>