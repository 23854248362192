import { environment as common } from './environment.playbison.firebase';


export const environment = {
  ...common,
  production: false,
  enableServiceWorker: false,
  apiUrl: 'https://api-test.fireball.casino',
  walletUrl: 'https://api-test.fireball.casino',
  paymentUrl: 'https://api-test.fireball.casino',
  uiBackend: 'https://prizeengine.playbison.com',
  // uiBackend: 'http://localhost:8000',
  fallbackLanguage: "de-AT",
  isDeploymentConfig: true,
  affiliateProgram: 'https://bison.partners',
  cmsDraftToken: null,
  baseCurrency: "EUR",
  enableTournamentsDisplay: true,
  enablePaymentIQ: true,
  chatToken: "b7240bde-642b-43b2-b46e-ab5af022f1cd",
  cmsApi: 'https://eu-central-1-lemon-casino.cdn.hygraph.com/content/clwg1qtcj000001w627f1ecdl/master',
  firebaseConfig: {
    apiKey: "AIzaSyDtD8Zb6x1zTbLi8PBH3SjDq6ORtkoafUE",
    authDomain: "for-fun-338904.firebaseapp.com",
    projectId: "for-fun-338904",
    storageBucket: "for-fun-338904.appspot.com",
    messagingSenderId: "736138600386",
    appId: "1:736138600386:web:dbef0b35eabc21cc20c05b",
    measurementId: "G-XG9ZNQ4BNY"
  },
  paymentIqConfig: {
    url: 'https://test-api.paymentiq.io/paymentiq/api',
    mid: 100624998,
    sessionIdName: 'forfun_ses',
    userPrefix: 'user'
  },
  checkSessionInterval: 5000,
  checkPopularGamesUsersInterval: 30000,
  checkInboxInterval: 30000,
  minBalanceThresholds: {
    USD: 10,
    EUR: 10,
    GBP: 10,
    PLN: 20,
    HUF: 1500,
    INR: 500,
    CAD: 30,
    AUD: 32,
    NZD: 35,
    ZAR: 150,
    NOK: 100
  },
  defaultDepositValues: {
    PLN: [50, 100, 200, 500, 1000],
    USD: [20, 50, 100, 200, 500],
    EUR: [20, 50, 100, 200, 500],
    GBP: [20, 50, 100, 200, 500],
    HUF: [5000, 10000, 20000, 50000, 100000],
    INR: [500, 1000, 2000, 5000, 10000],
    CAD: [20, 50, 100, 200, 500],
    AUD: [20, 50, 100, 200, 500],
    NZD: [20, 50, 100, 200, 500],
    ZAR: [150, 300, 500, 1000, 2000, 5000],
    NOK: [200, 500, 1000, 2000, 5000]
  }
};
