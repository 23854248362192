import { Apollo } from 'apollo-angular';
import { Component, Inject, Input, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, ROUTES } from '@angular/router';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { Page, PageResponse } from 'src/app/apollo/models/base-models';
import { STATIC_PAGE_CONTENT_QUERY } from 'src/app/apollo/static-page';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';
import { LoaderService } from 'src/app/services/utils/loader/loader.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { HOST_NAME, LICENCE } from 'src/app/app-routing.module';
import { WINDOW } from '@ng-web-apis/common';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { BaseComponent } from '../base/base.component';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { loadChatScript } from 'src/app/utils/load-chat';
import { AdformTrackingPage, AdformTrackingService } from 'src/app/services/tracking/adform-tracking.service';
import { LicenceService } from 'src/app/services/utils/licence.service';


type RichBanner = {
  url: SafeResourceUrl;
  width: number;
  height: number;
}
@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss']
})
export class StaticPageComponent extends BaseComponent {

  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo,
    private sanitizer: DomSanitizer,
    private toggleBackService: ToggleMenuBackService,
    private loaderService: LoaderService,
    public deviceDetector: DeviceDetectorService,
    public minimizeSiteService: MinimizeSiteService,
    public loginStatusService: LoginStatusService,
    private adformService: AdformTrackingService,
    private licenceConfigService: LicenceService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public license: string,
    @Inject(PLATFORM_ID) public platfromId: string,
    @Inject(ROUTES) private routes,
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(HOST_NAME) public hostname: string,

  ) { super() }

  private mapping: Map<string, string>;

  pageJson: Page;
  body: SafeHtml;
  isHtml: boolean = false;
  isNotFound: boolean = false;
  richBanner: RichBanner;

  @Input() name: string;
  @Input() category: string;
  @Input() standalone: boolean = true;

  replaceTemplateTags(content: string) {
    return content.replaceAll("{CasinoName}", this.licenceConfigService.getKey("name")).replaceAll("{Hostname}", this.hostname)
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const minimize = params.minimize === 'true';
      if (minimize) {
        this.minimizeSiteService.minimizePage();
      }
    });
    this.adformService.setTrackingPage(AdformTrackingPage.ALL_PAGES);


    this.toggleBackService.showBack();
    this.route.paramMap.pipe(
      takeUntil(this.unsubscribe),
      debounceTime(0),
      // filter(([params, map]) => !!params.get('name')),
      switchMap((params) => {
        let pageName = this.name;
        // if (params.get('name') !== null) pageName = map.get(params.get('slug') + '/' + params.get('name'));
        pageName = !!pageName ? pageName : params.get('name');
        let categoryName = !!this.category ? this.category : params.get('slug');
        return this.apollo.watchQuery<PageResponse>({
          query: STATIC_PAGE_CONTENT_QUERY,
          variables: {
            pageName: pageName,
            categoryName: categoryName,
            locale: this.locale,
            licenseName: this.license
          }
        }).valueChanges
      })
    ).subscribe((resp) => {
      console.log(resp.data);

      this.pageJson = resp.data.pages?.find(item => {
        return item.license === this.license
      }) || resp.data.pages[0]
      if (!this.pageJson) {
        this.isNotFound = true;
        return;
      }

      if (!!this.pageJson?.richBanner) {
        const richBannerResp = this.pageJson?.richBanner;


        if (isPlatformBrowser(this.platfromId)) {
          let computedWidth = richBannerResp.width;
          let computedHeight = richBannerResp.height;
          const innerWidth = this.windowRef.innerWidth;
          if (innerWidth < richBannerResp.width) {
            computedWidth = innerWidth;
            computedHeight = Math.floor(richBannerResp.height * (innerWidth / richBannerResp.width));
          }
          this.richBanner = {
            url: this.sanitizer.bypassSecurityTrustResourceUrl(`https://animations-playbison.web.app/?width=${computedWidth}&height=${computedHeight}&name=${richBannerResp.name}`),
            width: computedWidth,
            height: computedHeight
          }
        }

      }

      if (this.pageJson.name === "support") {
        if (isPlatformBrowser(this.platfromId)) {
          this.loginStatusService.getIfUserLogged().pipe(takeUntil(this.unsubscribe)).subscribe((status) => {
            loadChatScript(this.locale, status.username, status.fullInfo.first_name);
            console.log("chat enabled")
          });
        }
      }

      if (this.pageJson?.pageType === "html") {
        this.isHtml = true;
        this.prepareLandingPage(this.pageJson);
        let pageContent = this.pageJson.htmlContent;
        if (!!this.pageJson.content) {
          pageContent = this.pageJson.htmlContent.replace("{{content}}", this.pageJson.content.html);
        }
        this.pageJson.pageAdditionalContents.forEach((item) => {
          pageContent = pageContent.replace(`{{${item.name}}}`, item.content.html);
          pageContent = pageContent.replace(`{{${item.name}-url}}`, item.url);
        })
        this.body = this.sanitizer.bypassSecurityTrustHtml(this.replaceTemplateTags(pageContent))
      }
      else {
        if (this.pageJson?.pageType === "html") {
          this.isHtml = true;
          let pageContent = this.pageJson.htmlContent.replace("{{content}}", this.pageJson.content.html);
          this.pageJson.pageAdditionalContents.forEach((item) => {
            pageContent = pageContent.replace(`{{${item.name}}}`, item.content.html);
          })
          this.body = this.sanitizer.bypassSecurityTrustHtml(this.replaceTemplateTags(pageContent))
        }
        else {
          this.body = this.sanitizer.bypassSecurityTrustHtml(this.replaceTemplateTags(this.pageJson?.content?.html));
        }



        this.loaderService.hide();
      }
      const title = !!this.pageJson.seo?.metaTitle ? this.pageJson.seo.metaTitle : `${this.pageJson.displayName} | Fireball Casino`;

      const description = !!this.pageJson.seo?.metaDescription
        ? this.pageJson.seo.metaDescription
        : $localize`:@@description-static-page: Read to know more about ${this.pageJson.displayName} at Fireball Casino in "year". Register today to get your welcome bonus, play and win more with Fireball Casino.`;
      this.setSEOTags(title, description, this.pageJson.seo?.relCanonical, null, this.pageJson.seo?.noIndex);
    }, (error) => {
      // this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      // this.router.navigate(['/not-found']));
    });
  }

  prepareLandingPage(page: Page) {
    if (!!page.landingPage) {
      this.minimizeSiteService.minimizePage();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
