import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { USER_INFO_SESSION } from '../../http/base-http.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { LoginStatusService } from '../login/login-status.service';
import { LoggedStatus, UserInfo, UserInfoResponse } from '../login/login.models';
import { BaseUsermgrService } from '../../http/base-usermgr.service';
import { CookieService } from 'ngx-cookie-service';
import { HOST_NAME } from 'src/app/app-routing.module';
import { makeToken } from 'src/app/utils/csrf-tokem';
import { FirebaseAuthService } from '../firebase/firebase-auth.service';
import { WINDOW } from '@ng-web-apis/common';
import { isPlatformServer } from '@angular/common';

type UpdateUserInfo = {
  isLogged: LoggedStatus
  username: string
  currency: string
  fullInfo: UserInfoResponse
}
@Injectable({
  providedIn: 'root'
})
export class UserInfoService extends BaseUsermgrService {

  constructor(http: HttpClient,
    cookie: CookieService,
    private faService: FirebaseAuthService,
    private loginStatusService: LoginStatusService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(HOST_NAME) public hostName,
    @Inject(PLATFORM_ID) public platformId,
    @Inject(WINDOW) readonly windowRef: Window,
  ) {
    super(http, cookie, locale, hostName);
  }

  private enableChatForLoggedUser(email: string, name: string) {
    // let scriptElement = this._renderer2.createElement('script');
    // scriptElement.type = `text/javascript`;
    // scriptElement.src = "https://wchat.freshchat.com/js/widget.js";
    // this._renderer2.appendChild(this._document.body, scriptElement);


  }


  public disableChat() {
    const windowWrapper: any = this.windowRef;
    windowWrapper?.fcWidget?.hide();
  }

  public openChat() {
    const windowWrapper: any = this.windowRef;
    windowWrapper?.fcWidget?.show();
  }



  requestCheckUserInfo(): Observable<UserInfo> {
    if (isPlatformServer(this.platformId)) {
      this.loginStatusService.updateUserInfo({
        isLogged: LoggedStatus.notLogged,
      });
      return of({
        isLogged: LoggedStatus.notLogged,
        username: '',
        currency: '',
        fullInfo: null
      });
    }
    else {
      if (!!this.cookie.get('csrftoken') || this.cookie.get('csrftoken') === '') {
        this.cookie.set('csrftoken', makeToken(), 2592000, '/', this.hostName);
      }
      return this.get<UserInfoResponse>(USER_INFO_SESSION).pipe(
        switchMap((resp) => {
          if (resp.status === "logged") {
            this.cookie.set('already_user', '1', 1000 * 60 * 60 * 24 * 365, '/', this.hostName);
            this.loginStatusService.setLoginPartialStatus(true);
          }
          else {
            this.loginStatusService.setLoginPartialStatus(false);
          }

          return combineLatest({
            fbState: this.faService.getAuthState().pipe(
              take(1),
              switchMap((state) => {
                if (resp.status === "logged" && state?.uid !== resp?.login) {
                  return this.faService.signWithToken(resp?.firebase_token);
                }
                return of(state);
              })
            ),
            userInfo: of(resp)
          })
        }),
        switchMap((combinedResponse) => {
          const resp = combinedResponse.userInfo;
          // .subscribe((state) => {
          //   console.log("set state", state.uid)
          // });
          let status = resp.status === "logged" ? LoggedStatus.logged : LoggedStatus.notLogged
          if (resp.restore_password) {
            status = LoggedStatus.changePassword;
          }
          if (status === LoggedStatus.logged)
            this.enableChatForLoggedUser(resp.login, resp.first_name);

          return of({
            isLogged: status,
            username: resp.login,
            currency: resp.currency,
            fullInfo: resp
          });
        }),
        switchMap((updateInfo: UpdateUserInfo) => {
          return combineLatest({
            updateInfo: of(updateInfo),
            fbresp: updateInfo.isLogged !== LoggedStatus.changePassword ? this.faService.updateUserDatabase(updateInfo?.username) : of(null)
          });
        }),
        switchMap((resp) => {
          this.loginStatusService.updateUserInfo(
            resp.updateInfo
          );
          return this.loginStatusService.getLoginStatus();

        }),
        catchError((error: HttpErrorResponse | any) => {
          if (error?.status === 403) {
            this.loginStatusService.updateUserInfo({
              isLogged: LoggedStatus.notLogged,
            });
          } else {
            this.loginStatusService.updateUserInfo({
              isLogged: LoggedStatus.notLogged,
            });
          }
          console.log('error', error)

          // return an observable with a user-facing error message
          return this.loginStatusService.getLoginStatus();
        })
      );
    }
  }
}
