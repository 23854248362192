import { Apollo } from 'apollo-angular';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { SystemPage, SystemPageResponse } from 'src/app/apollo/models/base-models';
import { SYSTEM_PAGE_CONTENT_QUERY } from 'src/app/apollo/static-page';
import { BasePageComponent } from '../../base-page/base-page.component';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { LostPasswordService } from 'src/app/services/auth/lost-password/lost-password.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent extends BasePageComponent {

  page: SystemPage;
  body: SafeHtml;
  formSubmited: boolean = false;
  errorMsg: string;
  hasError: boolean = false;
  hidePassword = true;
  isSuccess: boolean;


  newPasswordForm = this.formBuilder.group({
    new_password: new UntypedFormControl('', [Validators.required, Validators.minLength(7)]),
    new_password_repeat: new UntypedFormControl('', []),
  });

  constructor(private apollo: Apollo,
    private formBuilder: UntypedFormBuilder,
    private lostPasswordService: LostPasswordService,
    @Inject(LOCALE_ID) public locale: string,
    public dialogRef: MatDialogRef<NewPasswordComponent>,
    private sanitizer: DomSanitizer,) { super() }

  ngOnInit(): void {
    this.apollo.watchQuery<SystemPageResponse>({
      query: SYSTEM_PAGE_CONTENT_QUERY,
      variables: {
        pageName: "new-password",
        locale: this.locale
      }
    }).valueChanges.pipe(takeUntil(this.unsubscribe),).subscribe((resp) => {
      this.page = resp.data.systemPage;
      this.body = this.sanitizer.bypassSecurityTrustHtml(this.page.content.html);
    }, (error) => {

    });
  }

  onSubmit(newPasswordForm) {
    if (this.formSubmited) return;
    this.errorMsg = null;
    this.formSubmited = true;
    newPasswordForm.controls['new_password_repeat'].setValue(newPasswordForm.get('new_password').value);

    this.lostPasswordService.setNewPassword(newPasswordForm).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.dialogRef.close();
    }, err => {
      this.formSubmited = false;
      if (err.status === 406) {
        this.errorMsg = err.error.error_message;
        this.hasError = true;
      } else {
        this.errorMsg = err.error?.error_message || $localize`:@@lostPaswordGenericError:Sorry, something went wrong.`;;
        this.hasError = true;
      }
    });
  }
}
