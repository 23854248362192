<div class="profile-body" [ngClass]="isDesktop ? 'desktop': 'mobile'">
  <div fxLayout="column">
    <ng-container *ngIf="size !== 'desktop-top' && size !== 'mobile-top'">
      <div class="top-container" fxLayout="row" fxLayoutAlign="center center" (click)="redirectToProfile()">
        <div class="avatar">
        <img src="assets/fireball/icons/avatar.svg" />
      </div>
        <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{username}}</div>
      </div>
      <div class="divider">
        <mat-divider></mat-divider>
      </div>
    </ng-container>
    <div class="content {{size}}" fxLayoutAlign="space-between start" fxLayout="row">
      <div class="profile-name" fxLayoutAlign="center start" fxLayout="column">
        <div class="thumbnail {{size}}" (click)="redirectToProfile()">
          <ng-container *ngIf="size === 'desktop-top' || size === 'mobile-top'; else showAvatar">
            <img *ngIf="gamificationIndex" src="assets/fireball/levels/icon-level-{{gamificationIndex}}.png" alt="icon-gamification" />
          </ng-container>

          <ng-template #showAvatar>
            <div class="spinner-level level {{size}}">
              <div class="spinner-container" (click)="redirectToProfile()">
                <div class="spinner-background">
                  <div style="height: 100%;" fxLayoutAlign="center center" fxLayout="row">
                    <div class="spinner-value" *ngIf="showGamification">
                      <!-- <img *ngIf="gamificationIndex" style="border: 0" src="assets/playbison/levels/icon-playbison-level-{{gamificationIndex}}.png" alt="icon-gamification" /> -->
                    </div>
                  </div>
                </div>
                <mat-progress-spinner #spinnerlevel color="accent" mode="determinate" [value]="gamificationPercentFill" [diameter]="spinnerDiameter">
                </mat-progress-spinner>
              </div>
              <div class="label" fxLayoutAlign="center center" fxLayout="column">
                <div class="entry"><ng-container i18n>level </ng-container> <b>{{gamificationLevel}}</b><br><ng-container i18n>Fireball Coins</ng-container></div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <ng-container *ngIf="size !== 'mobile-top'">
        <div class="spinner-level level {{size}}">
          <div class="spinner-container" (click)="redirectToProfile()">
            <div class="spinner-background">
              <div style="height: 100%;" fxLayoutAlign="center center" fxLayout="row">
                <div class="spinner-value" *ngIf="showGamification">
                  <!-- <img src="{{licenceService.getKey('icon-profile-info')}}" alt="icon-gamification" style="transform: scale(0.85); margin-top: 6px;" /> -->
                </div>
              </div>
            </div>
            <mat-progress-spinner #spinnerlevel color="accent" mode="determinate" [value]="gamificationPercentFill" [diameter]="spinnerDiameter">
            </mat-progress-spinner>
          </div>
          <div class="label" fxLayoutAlign="center center" fxLayout="column">
            <div class="entry"><ng-container i18n>level </ng-container> <b>{{gamificationLevel}}</b><br><ng-container i18n>Scratch Cards</ng-container></div>
          </div>
        </div>
        <div class="spinner-level chargeback {{size}}">
          <div class="spinner-container" (click)="redirectToProfile()">
            <div class="spinner-background">
              <div style="height: 100%;" fxLayoutAlign="center center" fxLayout="row">
                <div class="spinner-value" *ngIf="userChargeback">
                  <img src="{{licenceService.getKey('icon-m-cashback')}}" alt="icon-gamification" style="margin-top: 6px;" />
                </div>
              </div>
            </div>
            <mat-progress-spinner #spinnerchargeback color="accent" mode="determinate" [value]="chargebackPercentFill" [diameter]="spinnerDiameter">
            </mat-progress-spinner>
          </div>
          <div class="label" fxLayoutAlign="center center" fxLayout="column">
            <div class="entry">
              {{userChargeback?.value.toFixed(2)}} {{userChargeback?.currency}}
            </div>
            <div class="entry" i18n>cashback</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>