import { Apollo } from 'apollo-angular';
import { Component, EventEmitter, Inject, LOCALE_ID, Output } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { SafeHtml } from '@angular/platform-browser';

import { SystemPage, SystemPageResponse } from 'src/app/apollo/models/base-models';
import { SYSTEM_PAGE_CONTENT_QUERY } from 'src/app/apollo/static-page';
import { BasePageComponent } from '../../base-page/base-page.component';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LostPasswordService } from 'src/app/services/auth/lost-password/lost-password.service';
import { LostPasswordResponse } from 'src/app/services/auth/lost-password/lost-password.models';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { LicenceService } from 'src/app/services/utils/licence.service';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.scss']
})
export class LostPasswordComponent extends BasePageComponent {

  page: SystemPage;
  body: SafeHtml;
  bodyLast: SafeHtml;
  formSubmited: boolean = false;
  errorMsg: string;
  hasError: boolean = false;
  isDesktop: boolean = this.deviceService.isDesktop();
  lostPasswordResponse: LostPasswordResponse;
  isSubmited: boolean = false;
  enterEmail = $localize`:@@enter-email:Enter your email address`;


  lostPasswordForm = this.formBuilder.group({
    mail: ['', [Validators.required, Validators.email]],
  });
  @Output() closeEvent = new EventEmitter();

  constructor(private apollo: Apollo,
    private formBuilder: UntypedFormBuilder,
    private lostPasswordService: LostPasswordService,
    @Inject(LOCALE_ID) public locale: string,
    public deviceService: DetectDeviceService,
    protected licenceService: LicenceService
  ) { super() }

  initialize() {
    this.apollo.watchQuery<SystemPageResponse>({
      query: SYSTEM_PAGE_CONTENT_QUERY,
      variables: {
        pageName: "lost-password",
        locale: this.locale
      }
    }).valueChanges.pipe(take(1),).subscribe((resp) => {
      this.page = resp.data.systemPage;
      // this.body = this.sanitizer.bypassSecurityTrustHtml(this.page.content.html);
      let template = document.createElement("template");
      template.innerHTML = this.page.content.html;
      this.body = template.content.firstChild.textContent;
      this.bodyLast = template.content.lastChild.textContent;

    }, (error) => {

    });
  }

  ngOnInit(): void {
    this.initialize();
  }

  onSubmit(lostPasswordForm: FormGroup) {
    if (this.formSubmited) return;
    if (this.lostPasswordForm.controls.mail.errors) return;
    this.errorMsg = null;
    this.formSubmited = true;

    console.log('sended');

    this.lostPasswordService.requestLostPasswordinUser(lostPasswordForm).pipe(takeUntil(this.unsubscribe)).subscribe((resp: LostPasswordResponse) => {
      this.lostPasswordResponse = resp;
      this.isSubmited = true;
      console.log('complite')
    }, err => {
      this.formSubmited = false;
      if (err.status === 406) {
        this.errorMsg = err.error.error_message;
        this.hasError = true;
        console.log(this.errorMsg);
      }
    });
  }

  closeDialog() {
    this.closeEvent.next('/')
  }
}
