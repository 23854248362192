export const lostPasswordTrx: string = 'lost-password';
export const changePasswordTrx: string = 'change-password';
export const christmasCalendarTrx: string = 'christmas-calendar';
export const gamesPluralTrx: string = 'games';
export const providerTrx: string = 'provider';
export const recommendationsTrx: string = 'recommendations';
export const gamesCategoryTrx: string = 'category';
export const liveCasinoTrx: string = 'live-casino';
export const newGamesTrx: string = 'new';
export const mostPlayedGamesTrx: string = 'most-played';
export const topGamesTrx: string = 'top-games';
export const arcadeTrx: string = 'arcade';
export const aviatorTrx: string = 'aviator';
export const slotTrx: string = 'slot';
export const gameTrx: string = 'slots';
export const fireballSlotTrx: string = 'slots-fireball';
export const gameInfoTrx: string = 'game-info';
export const resumeGameTrx: string = 'resume-game';
export const editProfileTrx: string = 'edit-profile';
export const responsibleGaming: string = 'responsible-gaming';
export const launchGameTrx: string = 'launch-game';
export const paymentTrx: string = 'payment';
export const depositTrx: string = 'deposit';
export const selectDepositTrx: string = 'select-deposit';
export const withdrawalTrx: string = 'withdrawal';
export const pendingWithdrawalTrx: string = 'pending-withdrawals';
export const termsTrx: string = 'info/terms-conditions';
export const aboutTrx: string = 'info/about-us';
export const supportTrx: string = 'info/support';
export const faqTrx: string = 'info/faq';
export const selfExclusionTrx: string = 'info/self-exclusion';
export const promotionsTrx: string = 'info/promotions';
export const promoTrx: string = 'promo';
export const searchGameTrx: string = 'search-game';
export const gameHistoryTrx: string = 'history-game';
export const paymentHistoryTrx: string = 'history-payment';
export const lastPlayedFullTrx: string = 'last-played';
export const TreasureboxTrx: string = 'treasure-box';
export const myGamesTrx: string = 'my-games';
export const infoTrx: string = 'info';
export const bonusTrx: string = 'bonus';
export const boostedOfferTrx: string = 'boosted-offer';
export const inboxTrx: string = 'inbox';
export const streamerTournamentTrx: string = 'streamer-tournament';
export const uploadDocumentsTrx: string = 'upload-documents';

export const lemonLotteryTermsTrx: string = 'fireball';
export const treasureBoxesTrx: string = 'treasure-boxes';
export const scratchCardsTrx: string = 'bison-scratch-cards';
export const cashbackTrx: string = 'bison-cashback';


export const signUpTrx: string = 'signup';
export const loginTrx: string = 'login';

export const clientAreaTrx: string = 'client-area';
export const tournamentsTrx: string = 'tournaments';
export const missionsTrx: string = 'missions';
export const tournamentsIdTrx: string = 'id';
export const tournamentsAllTrx: string = 'tournaments-all';
export const clientAreaMainTrx: string = 'main';
export const redeemPromocodeTrx: string = 'redeem-promocode';
export const bonusQueueTrx: string = 'bonus-queue';
export const prizeBoxTrx: string = 'prize-box';
export const desktopTrx: string = 'desktop';

export const paymentUpiP2pTrx: string = 'upi-p2p';
export const paymentUpiFastTrx: string = 'upi-fast';
export const paymentApayTrx: string = 'apay';

export const indiaLpTrx: string = 'welcome';

