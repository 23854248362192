<div class="profile-wrapper mobile" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxFlex class="top-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <div class="avatar"><img src="{{licenceService.getKey('icon-avatar')}}" /></div>
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
      <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;padding-right: 8px;width: calc(100vw - 220px);" [title]="email" i18n>{{email}}</div>
      <div *ngIf="verified" class="verified" i18n>Verified</div>
    </div>
  </div>
  <div fxFlex="none" class="level-container" fxLayout="row" fxLayoutAlign="center center">
    <img *ngIf="lemonIconNameIndex" src="assets/fireball/levels/icon-level-{{lemonIconNameIndex}}.png" alt="icon-gamification" />
    <!-- <div *ngIf="lemonIconNameIndex" class="thumbnail mobile-top level-{{lemonIconNameIndex}}"></div> -->
    <div *ngIf="playerLoyalityName" class="label">{{playerLoyalityName}}</div>
  </div>
</div>
<div class="items">
  <div class="item-wrapper light">
    <div class="item">
      <div class="spinner-container">
        <div class="spinner-background">
          <img *ngIf="isPrizeBoxActive && showGamification" src="{{licenceService.getKey('icon-box-on')}}" alt="icon-gamification" />
          <img *ngIf="!isPrizeBoxActive && showGamification" src="{{licenceService.getKey('icon-box-on')}}" alt="icon-gamification" class="grade-out" />
        </div>
        <mat-progress-spinner #spinnerlevel color="accent" mode="determinate" [value]="gamificationPercentFill" [diameter]="68" style="stroke-linecap: round;">
        </mat-progress-spinner>
      </div>
      <div class="text-container">
        <div class="title" i18n>Level {{gamificationLevel}}</div>
        <div *ngIf="isPrizeBoxActive" i18n>Collect your reward!</div>
        <div *ngIf="!isPrizeBoxActive" i18n>Level {{gamificationLevel}} reached in {{gamificationPercentFill.toFixed(2)}}%</div>
      </div>

      <div class="info">
        <a [routerLink]="scratchCardsInfoUrl" rel="noreferrer" routerLinkActive="active">
          <img src="assets/fireball/icons/icon-info.svg" />
        </a>
      </div>
    </div>
    <button round-button filled="filled" color="warn" (click)="openPrizeBox()" *ngIf="isPrizeBoxActive" i18n>Collect!</button>
  </div>

  <div class="item-wrapper light">
    <div class="item">
      <div class="spinner-container">
        <div class="spinner-background">
          <img *ngIf="!userChargebackToClaim && showGamification" src="{{licenceService.getKey('icon-m-cashback')}}" alt="icon-gamification" />
          <img *ngIf="!!userChargebackToClaim && showGamification" src="{{licenceService.getKey('icon-m-cashback')}}" alt="icon-gamification" />
        </div>
        <mat-progress-spinner *ngIf="!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="chargebackPercentFill" [diameter]="68" style="stroke-linecap: round;">
        </mat-progress-spinner>
        <mat-progress-spinner *ngIf="!!userChargebackToClaim" #spinnerlevel color="accent" mode="determinate" [value]="100" [diameter]="68" style="stroke-linecap: round;">
        </mat-progress-spinner>
      </div>
      <div class="text-container">
        <div i18n>Cashback earned:</div>
        <div class="title" i18n>{{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.value.toFixed(2)}} {{(!!userChargebackToClaim? userChargebackToClaim : userChargeback)?.currency}}</div>
        <div *ngIf="!userChargebackToClaim"><span i18n>Next payout in:</span>&nbsp;{{chargebackFormatted}}</div>
      </div>
      <div class="info">
        <a [routerLink]="cashbackInfoUrl" rel="noreferrer" routerLinkActive="active">
          <img src="{{licenceService.getKey('icon-info')}}">
        </a>
      </div>
    </div>
    <button round-button filled="filled" *ngIf="!!userChargebackToClaim" color="warn" (click)="claimCashback()" i18n>Claim!</button>
  </div>

  <div class="item-wrapper light">
    <div class="item">
      <div class="icon-container">
        <img *ngIf="!userBonusLotteryResponse" src="{{licenceService.getKey('icon-wheel-on')}}" />
        <img *ngIf="userBonusLotteryResponse" src="{{licenceService.getKey('icon-wheel-on')}}" />
        <!-- <div *ngIf="userBonusLotteryResponse" class="animation" fxLayoutAlign="center center">
          <div [ngStyle]="{'height.px': canvasHeight}" #pixiAnimation></div>
        </div> -->
      </div>
      <div class="text-container">
        <div class="title" i18n>Fireball Wheel</div>
        <div *ngIf="userBonusLotteryResponse" i18n>Claim your free ticket!</div>
        <div *ngIf="!userBonusLotteryResponse" i18n>No active lottery</div>
      </div>
      <div class="info">
        <a [routerLink]="lotteryInfoUrl" rel="noreferrer" routerLinkActive="active">
          <img src="assets/fireball/icons/icon-info.svg">
        </a>
      </div>
    </div>
    <button round-button filled="filled" *ngIf="userBonusLotteryResponse" color="warn" (click)="openLotteryWheel()" i18n>Spin!</button>
  </div>

  <div class="item-wrapper dark">
    <div class="item">
      <div class="icon-container">
        <img src="assets/fireball/icons/icon-bonus.png" />
      </div>
      <div class="text-container">
        <div class="title" i18n>My Bonuses</div>
        <div i18n>Manage your bonuses</div>
      </div>
    </div>
    <button round-button filled="empty" color="warn" i18n [routerLink]="bonusesUrl" routerLinkActive="active">Proceed</button>
  </div>
</div>

<div class="main-wrap">
  <div class="lighter-bg" fxLayout="column" fxLayoutAlign="start center" [routerLink]="myGamesUrl" routerLinkActive="active">
    <img src="assets/fireball/icons/icon-my-games.png">
    <div class="label mobile">
      <div class="profile-title" i18n appInsertBrBetweenWords>My games</div>
    </div>
  </div>

  <div class="lighter-bg" fxLayout="column" fxLayoutAlign="start center" [routerLink]="editProfileUrl" routerLinkActive="active">
    <img src="{{licenceService.getKey('icon-edit-profile-page')}}">
    <div class="label mobile">
      <div class="profile-title" i18n appInsertBrBetweenWords>Edit Profile</div>
    </div>
  </div>

  <div class="lighter-bg" fxLayout="column" fxLayoutAlign="start center" [routerLink]="changePasswordUrl" routerLinkActive="active">
    <img src="{{licenceService.getKey('icon-cpassword')}}">
    <div class="label mobile">
      <div class="profile-title" i18n appInsertBrBetweenWords>Change Password</div>
    </div>
  </div>
</div>