<ng-container *ngIf="teaser">
    <ng-container *ngIf="isShown && !isClosedByUser">
        <div class="teaser-container" fxLayout="row" fxLayoutGap="9px" fxLayoutAlign="start center">
            <div (click)="openUrl()" fxLayoutAlign="center center" class="rectangle">
                <div *ngIf="teaserName === 'christmascalendar'" class="teser-christmas">
                    <!-- <img src="assets/icons/{{teaser.iconName}}" /> -->
                </div>
                <div *ngIf="!isIconFromAssets" class="teser-image"></div>
            </div>
            <ng-container [ngSwitch]="teaserName">
                <ng-container *ngSwitchCase="'bonusFirstDepositWithDate'">
                    <ng-container *ngTemplateOutlet="firstdepositbonus"></ng-container>
                </ng-container>
                <p *ngSwitchDefault (click)="openUrl()">{{teaser.msg}}</p>
            </ng-container>
            <!-- <mat-icon fxFlex="6" (click)="openUrl()" class="open">arrow_forward_ios</mat-icon> -->
            <mat-icon color="accent" *ngIf="isClosable" (click)="close()">close</mat-icon>
        </div>
    </ng-container>
</ng-container>

<ng-template #firstdepositbonus>
    <p (click)="openUrl()">{{teaser.msg}} <span *ngIf="countDown" class="counter">{{countDown.minutes}}:{{countDown.seconds}}</span></p>

</ng-template>

<ng-template #christmasteser>
    <div class="teaser-container slider-content">
        <div class="rectangle">
            <img src="assets/icons/{{teaser.iconName}}">
            <p>{{teaser.msg}}</p>
        </div>
        <mat-icon role="img" routerLink="{{teaser.url}}">arrow_forward_ios</mat-icon>
    </div>
</ng-template>

<swiper [config]="config" class="teaser-swiper" *ngIf="false">
    <ng-template swiperSlide *ngFor="let teaser of multiTeaser.teaser">
        <div class="teaser-container slider-content">
            <div class="rectangle">
                <img src="assets/icons/{{teaser.iconName}}">
                <p>{{teaser.msg}}</p>
            </div>
            <mat-icon role="img" routerLink="{{teaser.url}}">arrow_forward_ios</mat-icon>
        </div>
    </ng-template>
</swiper>
