<div class="prize-boxes-wrapper">
  <ng-container *ngIf="showBoxes">
    <div class="label-container" (click)="expand()" [ngClass]="isDesktop ? 'desktop': 'mobile'" *ngIf="!true else showboxes" fxLayout="row" fxLayoutGap="9px" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" class="rectangle">
        <img src="{{licenceService.getKey('treasure-box')}}" />
      </div>
      <p fxFlex="70" i18n>Scratch your scratch cards!</p>
      <button *ngIf="isDesktop" fxFlex="20" round-button color="warn" filled="empty" (click)="expand()" i18n>Click to scratch!</button>
      <div *ngIf="!isDesktop">
        <img src="{{licenceService.getKey('icon-right')}}" icon-btn size="large" alt="icon-arrow-right">
      </div>
    </div>
  </ng-container>
  <ng-template #showboxes>
    <ng-container *ngIf="!isSelectionPrizeVolatilityType else selectboxvolatilitytype">
      <div class="header" [ngClass]="isDesktop ? 'desktop': 'mobile'">
        <div class="category-title-h1" i18n>FIREBALLS DRAW</div>
        <div class="info" *ngIf="lotteryState !== lotteryStateEnum.FINISHED" i18n>Tap on the fireball to unveil the prize.</div>
        <div *ngIf="prizeBoxLottery && !askForNextLevel" class="left-info"><span i18n>You have {{leftToOpen}} fireball left</span> </div>
        <div *ngIf="prizeBoxLottery && askForNextLevel" class="next-level-button"><button mat-button color="primary" (click)="getPrizeBoxes()" i18n>next level ></button></div>
      </div>
      <div class="bottom-wrapper" [ngClass]="isDesktop ? 'desktop': 'mobile'" *ngIf="true">
        <div class="prize-wrapper">
          <div class="prize-card-wrapper" *ngFor="let scratchCard of  prizeBoxLottery?.prizeBoxes; let index = index;">
           <ng-container *ngIf="!scratchCard.isOpen">
            <img src="{{scratchCardImages.imageFront}}" (click)="removeImage(index, scratchCard)" alt="" class="scratch-front-image scratch-front-image-{{index}}"  [class.disable-icon]="leftToOpen === 0"/>
            <img src="{{scratchCardImages.imageMiddle}}" (click)="removeImage(index, scratchCard)" alt="" class="scratch-middle-image scratch-middle-image-{{index}}"  [class.disable-icon]="leftToOpen === 0"/>
            <img (click)="openPrize(scratchCard)" src="{{scratchCardImages.imageBack}}" alt="" class="scratch-front-back" />
           </ng-container> 
            <!-- <img (click)="openPrize(scratchCard)" src="assets/animations/fireball-scratchcard-animation/scratch-card-after.png" alt="" class="scratch-front-back" /> -->
            <div class="prize-info" (click)="openInfoDialog()" *ngIf="scratchCard?.prize?.displayName">
              <div>{{scratchCard.prize.displayName}}</div>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isDesktop">
        <ng-container *ngTemplateOutlet="closebuttons"></ng-container>
      </ng-container>
      <ng-container *ngIf="!isDesktop">
        <ng-container *ngTemplateOutlet="closebuttons"></ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</div>

<ng-template #closebuttons>
  <div class="buttons" fxLayoutAlign="center center" fxLayout="row">
    <button round-button filled="empty" color="purple" class="wheel-btn" (click)="close()" i18n>Close</button>
  </div>
</ng-template>

<ng-template #selectboxvolatilitytype>
  <div class="img-content">
    <div class="card card-align" (click)="selectPrizeBoxType(1)">
      <img src="assets/playbison/prize/card.png" alt="" width="90%">
      <p i18n>collect LESS with HIGHER prizes</p>
    </div>
    <div class="card card-radius" (click)="selectPrizeBoxType(0)">
      <img src="assets/playbison/prize/group.png" alt="" width="90%">
      <p i18n>collect MORE with STANDARD prizes</p>
    </div>
  </div>
</ng-template>