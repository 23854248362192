import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from 'src/app/modules/shared-material/shared-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedComponentsModule } from 'src/app/modules/shared-components/shared-components.module';
import { SharedServicesModule } from 'src/app/modules/shared-services/shared-services.module';
import { SwiperModule } from 'swiper/angular';
import { RouterModule } from '@angular/router';
import { MissionsCarouselComponent } from '../front-page/missions-carousel/missions-carousel.component';

@NgModule({
  declarations: [
    MissionsCarouselComponent
  ],
  imports: [
    CommonModule,
    SharedMaterialModule,
    SharedServicesModule,
    SharedComponentsModule,
    FlexLayoutModule,
    SwiperModule,
    RouterModule
  ],
  exports: [
    MissionsCarouselComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MissionsCarouselDynamicModule {

  public static components = {
    dynamicComponent: MissionsCarouselComponent,
  };
}
